import React from "react";
import { members } from "../common/shared";

function Members() {
  return (
    <div
      className="mx-auto bg-primary-20 flex flex-col py-16 "
      style={{ maxWidth: "1024px" }}
    >
      <h2 className="text-dark-blue py-2 pl-2 lg:pl-0 text-base font-bold">
        Members
      </h2>
      <div
        className="w-full flex flex-row  gap-6 sm:h-1/4 md:h-1/4 lg:h-fit sm:overflow-x-auto sm:whitespace-nowrap lg:flex-wrap overflow-hidden"
        style={{ overflow: "auto", whiteSpace: "nowrap" }}
      >
        {members.map((item) => {
          return (
            <div
              className="drop-shadow-4xl bg-white m-2 flex justify-center"
              style={{ minWidth: "250px", maxWidth: "300px" }}
            >
              <img src={item} className="justify-center " />
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Members;
