import { useAtom } from "jotai";
import { systemCConclusionAtom } from "./SystemC";

function ConclusionModalC() {
  const [systemCConclusion, setSystemCConclusion] = useAtom(
    systemCConclusionAtom
  );

  return (
    <div className="absolute left-0 top-2 z-40 bg-primary-10 w-[95%] md:w-1/2 h-fit p-11 rounded-md drop-shadow-4xl">
      <p className="font-bold">Conclusion</p>
      <p className="my-4">
        The main contributors to <strong>climate change</strong>{" "}
        impacts for system C are the{" "}
        <strong>feedstock production</strong> of natural gas and
        associated leakage as well as electricity use respectively
        emissions from electricity generation. The{" "}
        <strong>MEMBER</strong> materials -{" "}
        <strong>Pd-based membrane</strong> , <strong>catalyst</strong>{" "}
        , and <strong>sorbent</strong> - have a visible contribution,
        though not significant. The catalyst and the sorbent are the
        cause for higher emissions for the indicators ecosystem
        toxicity and <strong>human health</strong>. These indicators
        further show the potential reduced impacts arising from the
        recycling of PD-based membrane.{" "}
        <strong>Water consumption</strong> is strongly influenced by
        the production and use of the membrane and the catalyst.{" "}
        <strong>Resource</strong> use mainly reflects the consumption
        of the feedstock and indirect fossil fuel use inherent in the
        electricity mix that is used.
      </p>
      <p
        className=" cursor-pointer text-right text-base font-bold underline "
        onClick={() => {
          setSystemCConclusion(false);
        }}
      >
        READ LESS{" "}
      </p>
    </div>
  );
}

export default ConclusionModalC;
