import React from "react";
import { preCombustionDividerImage } from "../common/shared";

function PreCO2Divider() {
  return (
    <div
      id="systemA"
      className="relative h-fit md:h-64 z-0"
      style={{
        height: "250px",
        backgroundImage: `url(${preCombustionDividerImage})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        className="absolute h-full w-full z-10"
        style={{
          background: "rgba(255, 255, 255, 0.37)",
          backdropFilter: "blur(1.5px)",
        }}
      ></div>
      <div
        className=" flex flex-col px-4 lg:px-0 my-6 mx-auto "
        style={{
          maxWidth: "1024px",
        }}
      >
        <h2 className=" absolute top-6 z-30 text-dark-blue text-base font-semibold mb-5 ">
          SYSTEM A
        </h2>
        <div
          className=" absolute top-20 z-30 flex flex-col md:flex-row justify-start md:justify-around "
          style={{ maxWidth: "1024px" }}
        >
          <p className=" text-xl md:text-3xl font-bold leading-11 pr-0 md:pr-20 mb-2 md:mb-0 w-full md:w-1/2">
            Pre-combustion CO<sub>2</sub> capture
          </p>
          <p className=" whitespace-normal font-semibold text-md md:text-lg mr-0 md:mr-4 w-full md:w-1/2">
            CO<sub>2</sub> is separated in the syngas feed before
            combustion using Mixed Matrix Membranes (MMM).
          </p>
        </div>
      </div>
    </div>
  );
}

export default PreCO2Divider;
