import React from "react";
import { atom, useAtom } from "jotai";
import {
  climateChangeOptions,
  humanHealthOptions,
  ecosystemQualityOptions,
  resourcesDepletionOptions,
  waterConsumptionOptions,
  climateReferenceOptions,
  humanHealthReferenceOptions,
  ecosystemQualityReferenceOptions,
  resourcesDepletionReferenceOptions,
  waterConsumptionReferenceOptions,
} from "../common/shared.js";
import BarChart from "./BarChart.js";
import LegendA from "./LegendA.js";
import ChartInformation from "./ChartInformation.js";
import add from "../assets/add.png";
import subtract from "../assets/subtract.png";
import {
  dataCase1,
  dataCase2,
  dataCase3,
  climateDataWithReferenceCase1,
  referenceDataCase1,
  referenceDataCase2,
  referenceDataCase3,
} from "../data/systemA.js";
import BarChartNavigationSystemA from "./BarChartNavigationSystemA.js";
import Conclusion from "./Conclusion.js";
import ConclusionModal from "./ConclusionModal.js";
import Parameters from "./Parameters.js";

export const referenceAtom = atom(false);
export const currentDataAtom = atom(dataCase1);
export const currentBarChartAtom = atom("climate");
export const currentCaseAtom = atom(1);
export const systemAConclusionAtom = atom(false);

export default function SystemA() {
  const [currentData, setCurrentData] = useAtom(currentDataAtom);
  const [currentBarChart, setCurrentBarChart] = useAtom(
    currentBarChartAtom
  );
  const [currentCase, setCurrentCase] = useAtom(currentCaseAtom);
  const [currentLegendData, setCurrentLegendData] = React.useState({
    memberane: currentData.climateData.datasets[0].data,
    coalAsh: currentData.climateData.datasets[1].data,
    pbi: currentData.climateData.datasets[2].data,
    hardCoal: currentData.climateData.datasets[3].data,
    directEmissions: currentData.climateData.datasets[4].data,
  });
  const [reference, setReference] = useAtom(referenceAtom);
  const [systemAConclusion] = useAtom(systemAConclusionAtom);

  React.useEffect(() => {
    if (reference === false) {
      if (currentCase === 1) {
        setCurrentData(dataCase1);
      } else if (currentCase === 2) {
        setCurrentData(dataCase2);
      } else if (currentCase === 3) {
        setCurrentData(dataCase3);
      }
    } else if (reference) {
      if (currentCase === 1) {
        setCurrentData(referenceDataCase1);
      } else if (currentCase === 2) {
        setCurrentData(referenceDataCase2);
      } else if (currentCase === 3) {
        setCurrentData(referenceDataCase3);
      }
    }
  }, [reference, currentCase]);

  React.useEffect(() => {
    if (currentBarChart === "climate") {
      setCurrentLegendData({
        memberane: currentData.climateData.datasets[0].data,
        coalAsh: currentData.climateData.datasets[1].data,
        pbi: currentData.climateData.datasets[2].data,
        hardCoal: currentData.climateData.datasets[3].data,
        directEmissions: currentData.climateData.datasets[4].data,
      });
    } else if (currentBarChart === "humanhealth") {
      setCurrentLegendData({
        memberane: currentData.humanHealthData.datasets[0].data,
        coalAsh: currentData.humanHealthData.datasets[1].data,
        pbi: currentData.humanHealthData.datasets[2].data,
        hardCoal: currentData.humanHealthData.datasets[3].data,
        directEmissions: currentData.humanHealthData.datasets[4].data,
      });
    } else if (currentBarChart === "ecosystem_quality") {
      setCurrentLegendData({
        memberane: currentData.ecosystemQualityData.datasets[0].data,
        coalAsh: currentData.ecosystemQualityData.datasets[1].data,
        pbi: currentData.ecosystemQualityData.datasets[2].data,
        hardCoal: currentData.ecosystemQualityData.datasets[3].data,
        directEmissions:
          currentData.ecosystemQualityData.datasets[4].data,
      });
    } else if (currentBarChart === "resources_depletion") {
      setCurrentLegendData({
        memberane:
          currentData.resourcesDepletionData.datasets[0].data,
        coalAsh: currentData.resourcesDepletionData.datasets[1].data,
        pbi: currentData.resourcesDepletionData.datasets[2].data,
        hardCoal: currentData.resourcesDepletionData.datasets[3].data,
        directEmissions:
          currentData.resourcesDepletionData.datasets[4].data,
      });
    } else if (currentBarChart === "water_consumption") {
      setCurrentLegendData({
        memberane: currentData.waterConsumptionData.datasets[0].data,
        coalAsh: currentData.waterConsumptionData.datasets[1].data,
        pbi: currentData.waterConsumptionData.datasets[2].data,
        hardCoal: currentData.waterConsumptionData.datasets[3].data,
        directEmissions:
          currentData.waterConsumptionData.datasets[4].data,
      });
    }
  }, [currentData, reference, currentBarChart, currentCase]);

  const handleBarChart = (newBarChart) => {
    setCurrentBarChart(newBarChart);
  };

  const toggle = () => {
    setReference(!reference);
  };

  return (
    <div className="w-full bg-primary-20 px-0 md:px-[60px]">
      <BarChartNavigationSystemA handleBarChart={handleBarChart} />
      <div
        className="card-container h-fit mx-auto my-4 md:my-11 border-2 border-white rounded-3xl drop-shadow-4xl"
        style={{ maxWidth: "1024px" }}
      >
        <ChartInformation />
        <section className="w-[95%] h-fit mx-auto relative middleSection-container flex flex-col justify-center md:flex-row gap-4 ">
          <Conclusion />
          {systemAConclusion && <ConclusionModal />}

          <div className="flex flex-col w-full h-[200px] md:w-[50%] lg:w-[50%] mt-0 md:mt-4">
            <div className=" w-full flex flex-row justify-between border-t-2 border-x-2 border-primary-10 rounded-t-md  ">
              <button
                className={`w-1/3 py-4 px-8 ${
                  currentCase === 1
                    ? "bg-primary-10"
                    : "bg-transparent"
                } font-bold text-sm `}
                onClick={() => {
                  setCurrentCase(1);
                }}
              >
                CASE 1
              </button>
              <button
                className={`w-1/3 py-4 px-8 font-bold ${
                  currentCase === 2
                    ? "bg-primary-10"
                    : "bg-transparent"
                } text-sm`}
                onClick={() => {
                  setCurrentCase(2);
                }}
              >
                CASE 2
              </button>
              <button
                className={`w-1/3 py-4 px-8 font-bold ${
                  currentCase === 3 || currentCase === 4
                    ? "bg-primary-10"
                    : "bg-transparent"
                } text-sm `}
                onClick={() => {
                  setCurrentCase(3);
                }}
              >
                CASE 3
              </button>
            </div>
            <div className=" flex flex-col justify-start bg-primary-10  rounded-b-md drop-shadow-4xl p-3">
              <p className="text-xl font-bold ">
                Process parameters:
              </p>
              <Parameters system="systemA" />
            </div>
          </div>
        </section>
        <div className="flex flex-row w-full justify-end items-center ">
          <button
            className="w-fit py-4 px-2 font-bold text-sm flex flex-row justify-end items-center "
            onClick={toggle}
          >
            Reference System{" "}
            <div className="w-5 h-5 border-4 rounded-full border-primary-10 bg-primary-10 mx-4 flex flex-row justify-center items-center">
              <img src={reference ? subtract : add} alt="" />
            </div>
          </button>
        </div>
        <div
          className="w-full h-fit relative"
          style={{
            padding: "0px 20px",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          {!reference &&
            (() => {
              switch (currentBarChart) {
                case "climate":
                  return (
                    <BarChart
                      data={currentData.climateData}
                      options={climateChangeOptions}
                    />
                  );
                case "humanhealth":
                  return (
                    <BarChart
                      data={currentData.humanHealthData}
                      options={humanHealthOptions}
                    />
                  );
                case "ecosystem_quality":
                  return (
                    <BarChart
                      data={currentData.ecosystemQualityData}
                      options={ecosystemQualityOptions}
                    />
                  );
                case "resources_depletion":
                  return (
                    <BarChart
                      data={currentData.resourcesDepletionData}
                      options={resourcesDepletionOptions}
                    />
                  );
                case "water_consumption":
                  return (
                    <BarChart
                      data={currentData.waterConsumptionData}
                      options={waterConsumptionOptions}
                    />
                  );
                case "climate_reference":
                  return (
                    <BarChart
                      data={climateDataWithReferenceCase1}
                      options={referenceOptions}
                    />
                  );
                default:
                  return null;
              }
            })()}
          {reference &&
            (() => {
              switch (currentBarChart) {
                case "climate":
                  return (
                    <BarChart
                      data={currentData.climateData}
                      options={climateReferenceOptions}
                    />
                  );
                case "humanhealth":
                  return (
                    <BarChart
                      data={currentData.humanHealthData}
                      options={humanHealthReferenceOptions}
                    />
                  );
                case "ecosystem_quality":
                  return (
                    <BarChart
                      data={currentData.ecosystemQualityData}
                      options={ecosystemQualityReferenceOptions}
                    />
                  );
                case "resources_depletion":
                  return (
                    <BarChart
                      data={currentData.resourcesDepletionData}
                      options={resourcesDepletionReferenceOptions}
                    />
                  );
                case "water_consumption":
                  return (
                    <BarChart
                      data={currentData.waterConsumptionData}
                      options={waterConsumptionReferenceOptions}
                    />
                  );
                case "climate_reference":
                  return (
                    <BarChart
                      data={climateDataWithReferenceCase1}
                      options={referenceOptions}
                    />
                  );
                default:
                  return null;
              }
            })()}
          <LegendA
            currentLegendData={currentLegendData}
            currentBarChart={currentBarChart}
          />
          {reference && (
            <div className=" w-full px-4 lg:w-[210px] bg-primary-10 rounded-md drop-shadow-4xl p-3 mr-4 mt-4 lg:mt-0 mb-4 lg:mb-0 relative md:relative lg:absolute lg:bottom-8 lg:right-0">
              <p className="text-xs text-center">
                The results have not been reviewed according to ISO
                14040/44 standards
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
