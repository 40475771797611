import { atom, useAtom } from "jotai";
import elipse from "../assets/Ellipse 44.png";

const currentSectionAtom = atom("intro");

function Navigation() {
  const [currentSection, setCurrentSection] = useAtom(
    currentSectionAtom
  );

  const menuItemStyles =
    "h-[25px] flex flex-col justify-between items-center";

  return (
    <div className="w-full h-fit">
      <div
        className=" text-white py-4 flex flex-row gap-2  items-center justify-between mx-auto"
        style={{ maxWidth: "1024px" }}
      >
        <div className="ml-4 relative">
          <svg
            className="relative"
            width="82"
            height="56"
            viewBox="0 0 82 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.3"
              d="M70.0139 33.7789C67.7287 33.7789 65.6055 34.4606 63.8346 35.6293L63.9947 35.4067L48.7124 26.5938C48.7344 26.3016 48.7504 26.0075 48.7504 25.7093C48.7504 19.4683 43.6577 14.4099 37.3743 14.4099C35.001 14.4099 32.7998 15.1314 30.9768 16.3657L18.7121 9.29193C18.7121 4.16 14.5239 0 9.35707 0C4.19027 0 0 4.16 0 9.29391C0 14.4278 4.18827 18.5878 9.35707 18.5878C10.6998 18.5878 11.9765 18.3036 13.1311 17.7988L26.0101 25.284C26.0041 25.4251 26.0001 25.5682 26.0001 25.7113C26.0001 31.9523 31.0928 37.0107 37.3763 37.0107C39.5554 37.0107 41.5905 36.4005 43.3215 35.3451L58.8459 44.3687C58.8379 44.5416 58.8319 44.7165 58.8319 44.8914C58.8319 51.0271 63.8386 56 70.0159 56C76.1933 56 81.2 51.0271 81.2 44.8914C81.2 38.7558 76.1933 33.7829 70.0159 33.7829L70.0139 33.7789ZM16.7471 14.9863C17.0893 14.5491 17.3914 14.082 17.6516 13.5871L27.8571 19.518C27.5589 19.9692 27.2928 20.4422 27.0627 20.9332L16.7471 14.9843V14.9863ZM46.6573 32.2345C46.9574 31.8152 47.2276 31.3759 47.4677 30.9168L60.7629 38.6445C60.4808 39.0539 60.2286 39.4852 60.0045 39.9324L46.6573 32.2345Z"
              fill="white"
            />
          </svg>
          <a
            href="#Home"
            className="text-base text-white font-bold absolute top-4"
          >
            MEMBER
          </a>
        </div>
        <nav
          className="text-white py-4 flex flex-row items-center overflow-hidden"
          style={{ overflow: "auto", whiteSpace: "nowrap" }}
        >
          <div
            className=" bg-transparent items-center flex flex-row"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
            tabindex="-1"
          >
            <li className={menuItemStyles}>
              <a
                href="#Home"
                className=" text-xs text-white font-bold px-4 md:px-11 "
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-0"
                onClick={() => {
                  setCurrentSection("intro");
                }}
                style={{
                  opacity: `${
                    currentSection === "intro" ? "1" : "0.4"
                  }`,
                }}
              >
                INTRODUCTION
              </a>
              <img
                src={elipse}
                alt=""
                style={{
                  display: `${
                    currentSection === "intro" ? "inline" : "none"
                  }`,
                }}
              />
            </li>
            <li className={menuItemStyles}>
              <a
                href="#systemA"
                className=" text-xs text-white font-bold px-4 md:px-11 "
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-1"
                onClick={() => {
                  setCurrentSection("systemA");
                }}
                style={{
                  opacity: `${
                    currentSection === "systemA" ? "1" : "0.4"
                  }`,
                }}
              >
                SYSTEM A
              </a>
              <img
                src={elipse}
                alt=""
                style={{
                  display: `${
                    currentSection === "systemA" ? "inline" : "none"
                  }`,
                }}
              />
            </li>
            <li className={menuItemStyles}>
              <a
                href="#systemB"
                className=" text-xs text-white font-bold px-4 md:px-11 flex flex-col justify-center items-center"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-2"
                onClick={() => {
                  setCurrentSection("systemB");
                }}
                style={{
                  opacity: `${
                    currentSection === "systemB" ? "1" : "0.4"
                  }`,
                }}
              >
                SYSTEM B
              </a>
              <img
                src={elipse}
                alt=""
                style={{
                  display: `${
                    currentSection === "systemB" ? "inline" : "none"
                  }`,
                }}
              />
            </li>
            <li className={menuItemStyles}>
              <a
                href="#systemC"
                className="text-xs text-white font-bold px-3 md:px-11 flex flex-col justify-center items-center"
                role="menuitem"
                tabindex="-1"
                id="user-menu-item-2"
                onClick={() => {
                  setCurrentSection("systemC");
                }}
                style={{
                  opacity: `${
                    currentSection === "systemC" ? "1" : "0.4"
                  }`,
                }}
              >
                SYSTEM C
              </a>
              <img
                src={elipse}
                alt=""
                style={{
                  display: `${
                    currentSection === "systemC" ? "inline" : "none"
                  }`,
                }}
              />
            </li>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Navigation;
