const labels = ["", ""];

//REFERENCE DATA
export const climateReferenceData = [
  {
    label: "Feedstock production",
    data: [0.20998227],
    backgroundColor: "#FFC136",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Electricity",
    data: [0.50720449],
    backgroundColor: "#06BDF8",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Direct Emissions",
    data: [0.13501068],
    backgroundColor: "#7045EC",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Catalyst",
    data: [0.000972416553],
    backgroundColor: "#3CC1A1",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Other",
    data: [0.0029862275],
    backgroundColor: "#FCAAD0",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
];

export const humanHealthReferenceData = [
  {
    label: "Feedstock production",
    data: [0.000000099683325],
    backgroundColor: "#FFC136",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Electricity",
    data: [0.00000046566138],
    backgroundColor: "#06BDF8",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Direct Emissions",
    data: [0],
    backgroundColor: "#7045EC",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Catalyst",
    data: [0.000000003679060698],
    backgroundColor: "#3CC1A1",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Other",
    data: [0.0000000080324373],
    backgroundColor: "#FCAAD0",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
];

export const ecosystemQualityReferenceData = [
  {
    label: "Feedstock production",
    data: [0.023932922],
    backgroundColor: "#FFC136",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Electricity",
    data: [0.44006553],
    backgroundColor: "#06BDF8",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Direct Emissions",
    data: [0],
    backgroundColor: "#7045EC",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Catalyst",
    data: [0.001328652287],
    backgroundColor: "#3CC1A1",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Other",
    data: [0.036537192],
    backgroundColor: "#FCAAD0",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
];

export const resourcesDepletionReferenceData = [
  {
    label: "Feedstock production",
    data: [25.400962],
    backgroundColor: "#FFC136",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Electricity",
    data: [11.249739],
    backgroundColor: "#06BDF8",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Direct Emissions",
    data: [0],
    backgroundColor: "#7045EC",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Catalyst",
    data: [0.01450539817],
    backgroundColor: "#3CC1A1",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Other",
    data: [0.038979398],
    backgroundColor: "#FCAAD0",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
];

export const waterConsumptionReferenceData = [
  {
    label: "Feedstock production",
    data: [0.0002563739],
    backgroundColor: "#FFC136",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Electricity",
    data: [0.00706235],
    backgroundColor: "#06BDF8",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Direct Emissions",
    data: [0.017604],
    backgroundColor: "#7045EC",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Catalyst",
    data: [0.00000312595489],
    backgroundColor: "#3CC1A1",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Other",
    data: [0.0004257863],
    backgroundColor: "#FCAAD0",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
];

export const placeholderData = [
  {
    label: "blank",
    data: [0],
    backgroundColor: "transparent",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 2",
  },
  {
    label: "blank",
    data: [0],
    backgroundColor: "transparent ",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 2",
  },
  {
    label: "blank",
    data: [0],
    backgroundColor: "transparent ",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 2",
  },
];

export const totalData = {
  base: {
    climate: 0.65196099,
    humanHealth: 0.00000074989457,
    ecosystemQuality: 0.58655914,
    resourcesDepletion: 31.151082,
    waterConsumption: 0.012297329,
  },
  increasedEfficiency: {
    climate: 0.6164948115,
    humanHealth: 0.0000007173332855,
    ecosystemQuality: 0.5557876405,
    resourcesDepletion: 30.364446105,
    waterConsumption: 0.011803495505,
  },
  sorbentAttrition: {
    climate: 0.64089735,
    humanHealth: 0.00000073984306,
    ecosystemQuality: 0.57760888,
    resourcesDepletion: 30.915168,
    waterConsumption: 0.015049624,
  },
  catalystAttrition: {
    climate: 0.6350202,
    humanHealth: 0.00000067450536,
    ecosystemQuality: 0.53148855,
    resourcesDepletion: 30.835831,
    waterConsumption: 0.014107068,
  },
  efficiencySorbent: {
    climate: 0.647447375731497,
    humanHealth: 0.000000745750647078701,
    ecosystemQuality: 0.58264299463978,
    resourcesDepletion: 31.0509705283938,
    waterConsumption: 0.0122344811285101,
  },
  efficiencyCatalyst: {
    climate: 0.626466250350345,
    humanHealth: 0.000000636438902118489,
    ecosystemQuality: 0.669745993742802,
    resourcesDepletion: 30.605809811289,
    waterConsumption: 0.00850110392285552,
  },
  membrane: {
    climate: 0.61141765,
    humanHealth: 0.00000066380543,
    ecosystemQuality: 0.50693054,
    resourcesDepletion: 30.501862,
    waterConsumption: 0.014158709,
  },
  reference: {
    climate: 0.8561560841,
    humanHealth: 0.0000005770603112,
    ecosystemQuality: 0.5018642963,
    resourcesDepletion: 36.7041858,
    waterConsumption: 0.02535163615,
  },
};

// BASE SCENARIO
export const climateDataBaseScenario = {
  labels,
  datasets: [
    {
      label: "Feedstock production",
      data: [0.19081661],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Electricity",
      data: [0.23644119],
      backgroundColor: "#06BDF8",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct Emissions",
      data: [0.07],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Catalyst",
      data: [0.066160643],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Pd-membrane",
      data: [0.034670709],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Sorbent",
      data: [0.033517924],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Other",
      data: [0.0029862275],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const humanHealthDataBaseScenario = {
  labels,
  datasets: [
    {
      label: "Feedstock production",
      data: [0.000000090584957],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Electricity",
      data: [0.00000021707523],
      backgroundColor: "#06BDF8",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct Emissions",
      data: [0],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Catalyst",
      data: [0.00000029442544],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Pd-membrane",
      data: [0.00000039395722],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Sorbent",
      data: [0.000000030451601],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Other",
      data: [-0.0000277],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const ecosystemQualityDataBaseScenario = {
  labels,
  datasets: [
    {
      label: "Feedstock production",
      data: [0.0217485],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Electricity",
      data: [0.20514333],
      backgroundColor: "#06BDF8",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct Emissions",
      data: [0],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Catalyst",
      data: [0.215073],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Pd-membrane",
      data: [0.17310932],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Sorbent",
      data: [0.027115328],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Other",
      data: [-0.05563033585],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const resourcesDepletionDataBaseScenario = {
  labels,
  datasets: [
    {
      label: "Feedstock production",
      data: [23.082547],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Electricity",
      data: [5.2442393],
      backgroundColor: "#06BDF8",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct Emissions",
      data: [0],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Catalyst",
      data: [1.2311827],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Pd-membrane",
      data: [0.63022481],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Sorbent",
      data: [0.71471582],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Other",
      data: [0.2481722619],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const waterConsumptionDataBaseScenario = {
  labels,
  datasets: [
    {
      label: "Feedstock production",
      data: [0.00023297396],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Electricity",
      data: [0.0032922233],
      backgroundColor: "#06BDF8",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct Emissions",
      data: [0.00255],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Catalyst",
      data: [0.0042579124],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Pd-membrane",
      data: [0.00041038504],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Sorbent",
      data: [0.00044748195],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Other",
      data: [0.00110635236],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

// --- SYSTEM LEVEL

//INCREASED SYSTEM EFFICIENCY

export const climateDataIncreasedEfficiency = {
  labels,
  datasets: [
    {
      label: "Feedstock production",
      data: [0.19081661],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Electricity",
      data: [0.2009750115],
      backgroundColor: "#06BDF8",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct Emissions",
      data: [0.07],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Catalyst",
      data: [0.066160643],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Pd-membrane",
      data: [0.034670709],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Sorbent",
      data: [0.033517924],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Other",
      data: [0.0029862275],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const humanHealthDataIncreasedEfficiency = {
  labels,
  datasets: [
    {
      label: "Feedstock production",
      data: [0.000000090584957],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Electricity",
      data: [0.0000001845139455],
      backgroundColor: "#06BDF8",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct Emissions",
      data: [0],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Catalyst",
      data: [0.00000029442544],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Pd-membrane",
      data: [0.00000039395722],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Sorbent",
      data: [0.000000030451601],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Other",
      data: [-0.0000277],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const ecosystemQualityDataIncreasedEfficiency = {
  labels,
  datasets: [
    {
      label: "Feedstock production",
      data: [0.0217485],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Electricity",
      data: [0.1743718305],
      backgroundColor: "#06BDF8",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct Emissions",
      data: [0],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Catalyst",
      data: [0.215073],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Pd-membrane",
      data: [0.17310932],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Sorbent",
      data: [0.027115328],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Other",
      data: [-0.05563033585],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const resourcesDepletionDataIncreasedEfficiency = {
  labels,
  datasets: [
    {
      label: "Feedstock production",
      data: [23.082547],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Electricity",
      data: [4.457603405],
      backgroundColor: "#06BDF8",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct Emissions",
      data: [0],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Catalyst",
      data: [1.2311827],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Pd-membrane",
      data: [0.63022481],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Sorbent",
      data: [0.71471582],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Other",
      data: [0.2481722619],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const waterConsumptionDataIncreasedEfficiency = {
  labels,
  datasets: [
    {
      label: "Feedstock production",
      data: [0.00023297396],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Electricity",
      data: [0.002798389805],
      backgroundColor: "#06BDF8",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct Emissions",
      data: [0.00255],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Catalyst",
      data: [0.0042579124],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Pd-membrane",
      data: [0.00041038504],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Sorbent",
      data: [0.00044748195],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Other",
      data: [0.00110635236],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

//RENEWABLE ELECTRICITY

// --- MATERIAL LEVEL

// BASE SCENARIO MATERIAL LEVEL
export const climateDataBaseScenarioMaterialLevel = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.066160643],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Pd-membrane",
      data: [0.034670709],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Sorbent",
      data: [0.033517924],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const humanHealthDataBaseScenarioMaterialLevel = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.00000029442544],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Pd-membrane",
      data: [0.00000039395722],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Sorbent",
      data: [0.000000030451601],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const ecosystemQualityDataBaseScenarioMaterialLevel = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.215073],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Pd-membrane",
      data: [0.17310932],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Sorbent",
      data: [0.027115328],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const resourcesDepletionDataBaseScenarioMaterialLevel = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [1.2311827],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Pd-membrane",
      data: [0.63022481],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Sorbent",
      data: [0.71471582],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const waterConsumptionDataBaseScenarioMaterialLevel = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.0042579124],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Pd-membrane",
      data: [0.00041038504],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Sorbent",
      data: [0.00044748195],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

//ATTRITON STATE, SORBENT
export const climateDataSorbentAttrition = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.066160643],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.034670709],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.022454284],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

export const humanHealthDataSorbentAttrition = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.00000029442544],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.00000039395722],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.000000020400097],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

export const ecosystemQualityDataSorbentAttrition = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.215073],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.17310932],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.018165065],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

export const resourcesDepletionDataSorbentAttrition = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [1.2311827],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.63022481],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.47880149],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

export const waterConsumptionDataSorbentAttrition = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.0042579124],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.00041038504],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.00029977652],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

//ATTRITION STATE, CATALYST
export const climateDataBaseCatalystAttrition = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.049219855],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.034670709],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.033517924],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

export const humanHealthDataCatalystAttrition = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.00000021903623],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.00000039395722],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.000000030451601],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

export const ecosystemQualityDataCatalystAttrition = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.1600024],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.17310932],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.027115328],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

export const resourcesDepletionDataCatalystAttrition = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.91593178],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.63022481],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.71471582],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

export const waterConsumptionDataCatalystAttrition = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.0031676511],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.00041038504],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.00044748195],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

//INCREASED PRODUCTION EFFICIENCY, SORBENT
export const climateDataEfficiencySorbent = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.066160643],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.034670709],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.0290043097314968],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

export const humanHealthDataEfficiencySorbent = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.00000029442544],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.00000039395722],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.0000000263076780787011],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

export const ecosystemQualityDataEfficiencySorbent = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.215073],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.17310932],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.0231991826397804],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

export const resourcesDepletionDataEfficiencySorbent = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [1.2311827],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.63022481],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.614604348393792],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

export const waterConsumptionDataEfficiencySorbent = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.0042579124],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.00041038504],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.000384634078510119],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

//INCREASED PRODUCTION EFFICIENCY, CATALYST
export const climateDataEfficiencyCatalyst = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.0406659033503446],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.034670709],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.033517924],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

export const humanHealthDataEfficiencyCatalyst = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.000000180969772118489],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.00000039395722],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.000000030451601],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

export const ecosystemQualityDataEfficiencyCatalyst = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.298259853742802],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.17310932],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.027115328],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

export const resourcesDepletionDataEfficiencyCatalyst = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.685910511288965],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.63022481],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.71471582],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

export const waterConsumptionDataEfficiencyCatalyst = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.000461687322855517],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.00041038504],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.00044748195],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

//INCREASED MEMBRANE LIFETIME
export const climateDataMembrane = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.066160643],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.0086920933],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.033517924],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

export const humanHealthDataMembrane = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.00000029442544],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.000000098766739],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.000000030451601],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

export const ecosystemQualityDataMembrane = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.215073],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.043399238],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.027115328],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

export const resourcesDepletionDataMembrane = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [1.2311827],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.15800002],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.71471582],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

export const waterConsumptionDataMembrane = {
  labels,
  datasets: [
    {
      label: "Catalyst",
      data: [0.0042579124],
      backgroundColor: "#3CC1A1",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Pd-membrane",
      data: [0.00010288526],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
    {
      label: "Sorbent",
      data: [0.00044748195],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 1",
    },
  ],
};

export const dataBaseScenario = {
  climateData: climateDataBaseScenario,
  humanHealthData: humanHealthDataBaseScenario,
  ecosystemQualityData: ecosystemQualityDataBaseScenario,
  resourcesDepletionData: resourcesDepletionDataBaseScenario,
  waterConsumptionData: waterConsumptionDataBaseScenario,
};

export const dataBaseScenarioMaterialLevel = {
  climateData: climateDataBaseScenarioMaterialLevel,
  humanHealthData: humanHealthDataBaseScenarioMaterialLevel,
  ecosystemQualityData: ecosystemQualityDataBaseScenarioMaterialLevel,
  resourcesDepletionData:
    resourcesDepletionDataBaseScenarioMaterialLevel,
  waterConsumptionData: waterConsumptionDataBaseScenarioMaterialLevel,
};

export const dataIncreasedEfficiency = {
  climateData: climateDataIncreasedEfficiency,
  humanHealthData: humanHealthDataIncreasedEfficiency,
  ecosystemQualityData: ecosystemQualityDataIncreasedEfficiency,
  resourcesDepletionData: resourcesDepletionDataIncreasedEfficiency,
  waterConsumptionData: waterConsumptionDataIncreasedEfficiency,
};

export const dataSorbentAttrition = {
  climateData: climateDataSorbentAttrition,
  humanHealthData: humanHealthDataSorbentAttrition,
  ecosystemQualityData: ecosystemQualityDataSorbentAttrition,
  resourcesDepletionData: resourcesDepletionDataSorbentAttrition,
  waterConsumptionData: waterConsumptionDataSorbentAttrition,
};

export const dataCatalystAttrition = {
  climateData: climateDataBaseCatalystAttrition,
  humanHealthData: humanHealthDataCatalystAttrition,
  ecosystemQualityData: ecosystemQualityDataCatalystAttrition,
  resourcesDepletionData: resourcesDepletionDataCatalystAttrition,
  waterConsumptionData: waterConsumptionDataCatalystAttrition,
};

export const dataEfficiencySorbent = {
  climateData: climateDataEfficiencySorbent,
  humanHealthData: humanHealthDataEfficiencySorbent,
  ecosystemQualityData: ecosystemQualityDataEfficiencySorbent,
  resourcesDepletionData: resourcesDepletionDataEfficiencySorbent,
  waterConsumptionData: waterConsumptionDataEfficiencySorbent,
};

export const dataEfficiencyCatalyst = {
  climateData: climateDataEfficiencyCatalyst,
  humanHealthData: humanHealthDataEfficiencyCatalyst,
  ecosystemQualityData: ecosystemQualityDataEfficiencyCatalyst,
  resourcesDepletionData: resourcesDepletionDataEfficiencyCatalyst,
  waterConsumptionData: waterConsumptionDataEfficiencyCatalyst,
};

export const dataMembrane = {
  climateData: climateDataMembrane,
  humanHealthData: humanHealthDataMembrane,
  ecosystemQualityData: ecosystemQualityDataMembrane,
  resourcesDepletionData: resourcesDepletionDataMembrane,
  waterConsumptionData: waterConsumptionDataMembrane,
};

//REFERENCE BASE DATA
export const climateDataWithReferenceBase = {
  labels: [""],
  datasets: [
    ...climateDataBaseScenario.datasets,
    ...climateReferenceData,
    ...placeholderData,
  ],
};

export const humanHealthDataWithReferenceBase = {
  labels: [""],
  datasets: [
    ...humanHealthDataBaseScenario.datasets,
    ...humanHealthReferenceData,
    ...placeholderData,
  ],
};

export const ecosystemQualityDataWithReferenceBase = {
  labels: [""],
  datasets: [
    ...ecosystemQualityDataBaseScenario.datasets,
    ...ecosystemQualityReferenceData,
    ...placeholderData,
  ],
};

export const resourcesDepletionDataWithReferenceBase = {
  labels: [""],
  datasets: [
    ...resourcesDepletionDataBaseScenario.datasets,
    ...resourcesDepletionReferenceData,
    ...placeholderData,
  ],
};

export const waterConsumptionDataWithReferenceBase = {
  labels: [""],
  datasets: [
    ...waterConsumptionDataBaseScenario.datasets,
    ...waterConsumptionReferenceData,
    ...placeholderData,
  ],
};

//REFERENCE INCREASED EFFICIENCY DATA
export const climateDataWithReferenceIncreasedEfficiency = {
  labels: [""],
  datasets: [
    ...climateDataIncreasedEfficiency.datasets,
    ...climateReferenceData,
    ...placeholderData,
  ],
};

export const humanHealthDataWithReferenceIncreasedEfficiency = {
  labels: [""],
  datasets: [
    ...humanHealthDataIncreasedEfficiency.datasets,
    ...humanHealthReferenceData,
    ...placeholderData,
  ],
};

export const ecosystemQualityDataWithReferenceIncreasedEfficiency = {
  labels: [""],
  datasets: [
    ...ecosystemQualityDataIncreasedEfficiency.datasets,
    ...ecosystemQualityReferenceData,
    ...placeholderData,
  ],
};

export const resourcesDepletionDataWithReferenceIncreasedEfficiency =
  {
    labels: [""],
    datasets: [
      ...resourcesDepletionDataIncreasedEfficiency.datasets,
      ...resourcesDepletionReferenceData,
      ...placeholderData,
    ],
  };

export const waterConsumptionDataWithReferenceIncreasedEfficiency = {
  labels: [""],
  datasets: [
    ...waterConsumptionDataIncreasedEfficiency.datasets,
    ...waterConsumptionReferenceData,
    ...placeholderData,
  ],
};

export const referenceDataBase = {
  climateData: climateDataWithReferenceBase,
  humanHealthData: humanHealthDataWithReferenceBase,
  ecosystemQualityData: ecosystemQualityDataWithReferenceBase,
  resourcesDepletionData: resourcesDepletionDataWithReferenceBase,
  waterConsumptionData: waterConsumptionDataWithReferenceBase,
};

export const referenceDataIncreasedEfficiency = {
  climateData: climateDataWithReferenceIncreasedEfficiency,
  humanHealthData: humanHealthDataWithReferenceIncreasedEfficiency,
  ecosystemQualityData:
    ecosystemQualityDataWithReferenceIncreasedEfficiency,
  resourcesDepletionData:
    resourcesDepletionDataWithReferenceIncreasedEfficiency,
  waterConsumptionData:
    waterConsumptionDataWithReferenceIncreasedEfficiency,
};
