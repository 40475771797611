import image22 from "../assets/Group 1129.jpg";
import image21 from "../assets/Group 1130.jpg";
import image20 from "../assets/Group 1131.jpg";
import image19 from "../assets/Group 1132.jpg";
import image18 from "../assets/Group 1133.jpg";
import image17 from "../assets/Group 1134.jpg";
import image16 from "../assets/Group 1135.jpg";
import image15 from "../assets/Group 1136.jpg";
import image14 from "../assets/Group 1137.jpg";
import image13 from "../assets/Group 1138.jpg";
import image12 from "../assets/Group 1139.jpg";
import image9 from "../assets/Group 1140.jpg";
import image8 from "../assets/Group 1141.jpg";
import image7 from "../assets/Group 1142.jpg";
import image6 from "../assets/Group 1143.jpg";
import image5 from "../assets/Group 1144.jpg";
import image4 from "../assets/Group 1145.jpg";
import firstDividerImage from "../assets/firstDividerImage.png";
import secondDividerImage from "../assets/secondDividerImage.png";
import thirdDividerImage from "../assets/thirdDividerImage.png";
import climateChangeIcon from "../assets/climateChangeIcon.png";
import humanHealthIcon from "../assets/humanHealthIcon.png";
import ecosystemQualityIcon from "../assets/ecosystemQualityIcon.png";
import resourcesDepletionIcon from "../assets/resourcesDepletionIcon.png";
import waterConsumptionIcon from "../assets/waterConsumptionIcon.png";

function toPrecisionNotZero(num) {
  return num.toPrecision(3).replace(/0+$/, "");
}

export const dataUnits = {
  climate: "kg CO₂-eq",
  humanHealth: "DALY",
  ecosystemQuality: "PDF.m².y",
  resourcesDepletion: "MJ",
  waterConsumption: "m³",
};

export const options = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      min: 0.001,
      max: 0.05,
      grace: "5%",
      title: {
        display: true,
        text: "kg CO2 eq/ kg CO2 captured",
      },
      ticks: {
        stepSize: 0.0052,
      },
    },
  },
};

export const climateChangeOptions = {
  aspectRatio: 2,
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      max: 0.05,
      grace: "5%",
      title: {
        display: true,
        text: "kg CO₂-eq/kg CO₂ Captured ",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return val.toExponential();
        },
      },
    },
  },
};

export const systemBClimateChangeOptions = {
  aspectRatio: 2,
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      max: 0.12,
      grace: "5%",
      title: {
        display: true,
        text: "kg CO₂-eq/kg CO₂ Captured ",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return val.toExponential();
        },
      },
    },
  },
};

export const systemCclimateChangeOptions = {
  aspectRatio: 2,
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      max: 0.7,
      grace: "5%",
      title: {
        display: true,
        text: "kg CO₂-eq/m³ H2 ",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return val.toExponential();
        },
      },
    },
  },
};

export const climateChangeOptionsBase = {
  aspectRatio: 2,
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      max: 0.14,
      grace: "5%",
      title: {
        display: true,
        text: "kg CO₂-eq/m³ H2",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return val.toExponential();
        },
      },
    },
  },
};

export const humanHealthOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      max: 0.00000004,
      grace: "5%",
      title: {
        display: true,
        text: "DALY/kg CO₂ Captured",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return Number.parseFloat(val).toExponential(1);
        },
      },
    },
  },
};

export const systemBhumanHealthOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      max: 0.0000001,
      grace: "5%",
      title: {
        display: true,
        text: "DALY/kg CO₂ Captured",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return Number.parseFloat(val).toExponential(1);
        },
      },
    },
  },
};

export const systemChumanHealthOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      grace: "5%",
      title: {
        display: true,
        text: "DALY/m³ H2",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return Number.parseFloat(val).toExponential(1);
        },
      },
    },
  },
};

export const systemChumanHealthOptionsBase = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      max: 0.0000008,
      grace: "5%",
      title: {
        display: true,
        text: "DALY/m³ H2",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return Number.parseFloat(val).toExponential(1);
        },
      },
    },
  },
};

export const ecosystemQualityOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      min: 0.0,
      max: 0.008,
      grace: "5%",
      title: {
        display: true,
        text: "PDF.m².y/kg CO₂ Captured",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return Number.parseFloat(val).toExponential(1);
        },
      },
    },
  },
};

export const systemBecosystemQualityOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      min: 0.0,
      max: 0.018,
      grace: "5%",
      title: {
        display: true,
        text: "PDF.m².y/kg CO₂ Captured ",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return Number.parseFloat(val).toExponential(1);
        },
      },
    },
  },
};

export const systemCecosystemQualityOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      max: 0.8,
      grace: "5%",
      title: {
        display: true,
        text: "PDF.m².y/m³ H2 ",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return Number.parseFloat(val).toExponential(1);
        },
      },
    },
  },
};

export const systemCecosystemQualityMaterialOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      max: 0.6,
      grace: "5%",
      title: {
        display: true,
        text: "PDF.m².y/m³ H2",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return Number.parseFloat(val).toExponential(1);
        },
      },
    },
  },
};

export const resourcesDepletionOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      min: 0.0,
      max: 2.3,
      grace: "5%",
      title: {
        display: true,
        text: "MJ/kg CO₂ Captured",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
      },
    },
  },
};

export const systemBresourcesDepletionOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      min: 0.0,
      max: 5.0,
      grace: "5%",
      title: {
        display: true,
        text: "MJ/kg CO₂ Captured",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
      },
    },
  },
};

export const systemCresourcesDepletionOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      max: 39,
      grace: "5%",
      title: {
        display: true,
        text: "MJ/m³ H2",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return Number.parseFloat(val).toExponential(1);
        },
      },
    },
  },
};

export const systemCresourcesDepletionMaterialOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      max: 3,
      grace: "5%",
      title: {
        display: true,
        text: "MJ/m³ H2",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return Number.parseFloat(val).toExponential(1);
        },
      },
    },
  },
};

export const waterConsumptionOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      max: 0.0002,
      grace: "5%",
      title: {
        display: true,
        text: "m³/kg CO₂ Captured",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return Number.parseFloat(val).toExponential(1);
        },
      },
    },
  },
};

export const systemBwaterConsumptionOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      max: 0.0004,
      grace: "5%",
      title: {
        display: true,
        text: "m³/kg CO₂ Captured",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return Number.parseFloat(val).toExponential(1);
        },
      },
    },
  },
};

export const systemCwaterConsumptionOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      max: 0.014,
      grace: "5%",
      title: {
        display: true,
        text: "m³/m³ H2",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return Number.parseFloat(val).toExponential(1);
        },
      },
    },
  },
};

export const systemCwaterConsumptionMaterialLevelOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      max: 0.006,
      grace: "5%",
      title: {
        display: true,
        text: "m³/m³ H2",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return Number.parseFloat(val).toExponential(1);
        },
      },
    },
  },
};

export const climateReferenceOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      grace: "5%",
      title: {
        display: true,
        text: "kg CO₂-eq/kg CO₂ Captured",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return val.toExponential();
        },
      },
    },
  },
};

export const humanHealthReferenceOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      max: 0.000000111,
      grace: "5%",
      title: {
        display: true,
        text: "DALY/kg CO₂ Captured",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return val.toExponential();
        },
      },
    },
  },
};

export const systemBhumanHealthReferenceOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      max: 0.00000045,
      grace: "5%",
      title: {
        display: true,
        text: "DALY/kg CO₂ Captured",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return val.toExponential();
        },
      },
    },
  },
};

export const ecosystemQualityReferenceOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      min: 0.0,
      max: 0.018,
      grace: "5%",
      title: {
        display: true,
        text: "PDF.m².y/kg CO₂ Captured",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        stepSize: 0.003,
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
      },
    },
  },
};

export const systemBecosystemQualityReferenceOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      min: 0.0,
      max: 0.12,
      grace: "5%",
      title: {
        display: true,
        text: "PDF.m².y/kg CO₂ Captured ",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return val.toExponential();
        },
      },
    },
  },
};

export const resourcesDepletionReferenceOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      min: 0.0,
      max: 4.4,
      grace: "5%",
      title: {
        display: true,
        text: "MJ/kg CO₂ Captured",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        stepSize: 0.4,
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
      },
    },
  },
};

export const systemBresourcesDepletionReferenceOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      min: 0.0,
      max: 14,
      grace: "5%",
      title: {
        display: true,
        text: "MJ/kg CO₂ Captured",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
      },
    },
  },
};

export const waterConsumptionReferenceOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      max: 0.00035,
      grace: "5%",
      title: {
        display: true,
        text: "m³/kg CO₂ Captured",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return Number.parseFloat(val).toExponential(1);
        },
      },
    },
  },
};

export const systemBwaterConsumptionReferenceOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      max: 0.0014,
      grace: "5%",
      title: {
        display: true,
        text: "m³/kg CO₂ Captured",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return Number.parseFloat(val).toExponential(1);
        },
      },
    },
  },
};

export const systemCwaterConsumptionReferenceOptions = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        color: "rgba(84,124,164, 0.3)",
        borderColor: "transparent",
      },
      max: 0.028,
      grace: "5%",
      title: {
        display: true,
        text: "m³/m³ H2",
        padding: 6,
        color: "#547CA4",
        font: {
          size: 14,
          family: "source sans pro",
        },
      },
      ticks: {
        color: "#547CA4",
        weight: "600",
        font: {
          size: 14,
          weight: "bold",
          family: "source sans pro",
        },
        callback: function (val) {
          if (val === 0) return "0";
          return Number.parseFloat(val).toExponential(1);
        },
      },
    },
  },
};

export const communicateInformation = [
  {
    title: "WHAT",
    text: "MEMBER aimes to demonstrate current state-of-the-art CO₂ capture technologies in an industrially relevant environment.",
  },
  {
    title: "WHY",
    text: "Outperform current technologies for pre- and post-combustion CO₂ capture in power plants as well as H₂ generation with integrated CO₂ capture to meet the targets of the European SET plan.",
  },
  {
    title: "HOW",
    text: "MEMBER scaled-up and manufactured advanced materials and proved their added value in terms of sustainability and performance under industrially relevant conditions (TRL 6).",
  },
];

export const preCombustionDividerImage = firstDividerImage;
export const postCombustionDividerImage = secondDividerImage;
export const h2ProductionDividerImage = thirdDividerImage;

export const members = [
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image20,
  image21,
  image22,
];

export const climateChangeImage = climateChangeIcon;
export const humanHealthImage = humanHealthIcon;
export const ecosystemQualityImage = ecosystemQualityIcon;
export const resourcesDepletionImage = resourcesDepletionIcon;
export const waterConsumptionImage = waterConsumptionIcon;

export const chartInformation = {
  climate: {
    image: climateChangeImage,
    title: "Climate change",
    description: `Potential impact on climate change from greenhouse gas
    emissions - considers the midpoint category “global
    warming".`,
    units: "kg CO₂-eq",
  },
  humanHealth: {
    image: humanHealthImage,
    title: "Human health",
    description: `Potential impact on human health caused by emissions -
    considers human toxicity (carcinogenic and
    non-carcinogenic), respiratory inorganics, ionizing
    radiation, ozone layer depletion and respiratory organics'`,
    units: "DALY",
    unitDescription: "(disability-adjusted life years)",
  },
  ecosystemQuality: {
    image: ecosystemQualityImage,
    title: "Ecosystem quality",
    description: `Potential impact on ecosystems (biodiversity, species, and
      their inhabitant) caused by emissions or resource use -
      considers aquatic ecotoxicity, terrestrial ecotoxicity,
      terrestrial acidification & nutrification, aquatic
      eutrophication, aquatic acidification, water turbined and
      land occupation.`,
    units: "PDF.m².y",
    unitDescription: `(potentially disappeared
      fraction of species over 1 m² over 1 year)`,
  },
  resourcesDepletion: {
    image: resourcesDepletionImage,
    title: "Resource depletion",
    description: `Potential impact on resource depletion from resource use
    (e.g., fossil fuels and minerals) - considers
    non-renewable energy and mineral extraction.`,
    units: "MJ",
  },
  waterConsumption: {
    image: waterConsumptionImage,
    title: "Water consumption",
    description: `Amount of water withdrawal - considers water (whether
      evaporated, consumed, or released again downstream)
      excluding turbined water (i.e., water flowing through
      hydropower generation), considers drinking water,
      irrigation water and water for and in industrialized
      processes (including cooling water), fresh water and sea
      water.`,
    units: "m³",
  },
};
