import React from "react";
import { atom, useAtom } from "jotai";
import add from "../assets/add.png";
import subtract from "../assets/subtract.png";
import {
  dataBaseScenario,
  dataCatalystAttrition,
  dataEfficiencySorbent,
  dataEfficiencyCatalyst,
  dataIncreasedEfficiency,
  dataMembrane,
  dataSorbentAttrition,
  referenceDataBase,
  referenceDataIncreasedEfficiency,
  dataBaseScenarioMaterialLevel,
} from "../data/systemC";
import { currentBarChartAtom } from "./SystemA";
import {
  systemCclimateChangeOptions,
  systemChumanHealthOptions,
  systemCecosystemQualityOptions,
  systemCecosystemQualityMaterialOptions,
  systemCresourcesDepletionOptions,
  systemCresourcesDepletionMaterialOptions,
  systemCwaterConsumptionOptions,
  systemCwaterConsumptionReferenceOptions,
  systemCwaterConsumptionMaterialLevelOptions,
  climateChangeOptionsBase,
  systemChumanHealthOptionsBase,
} from "../common/shared";
import ConclusionC from "./ConclusionC";
import ConclusionModalC from "./ConclusionModalC";
import ChartInformation from "./ChartInformation.js";
import BarChartNavigationSystemA from "./BarChartNavigationSystemA";
import dropdownVector from "../assets/dropdownVector.png";
import BarChart from "./BarChart";
import LegendC from "./LegendC";

export const systemCConclusionAtom = atom(false);
export const systemCreferenceAtom = atom(false);
export const systemCcurrentDataAtom = atom(dataBaseScenario);
export const systemCcurrentDataMaterialAtom = atom(
  dataSorbentAttrition
);
export const currentLevelAtom = atom("systemLevel");
export const currentScenarioAtom = atom("Base scenario");
export const dropdownToggleAtom = atom(false);

function SystemC() {
  const [currentData, setCurrentData] = useAtom(
    systemCcurrentDataAtom
  );
  const [currentDataMaterial, setCurrentDataMaterial] = useAtom(
    systemCcurrentDataMaterialAtom
  );
  const [currentBarChart, setCurrentBarChart] = useAtom(
    currentBarChartAtom
  );
  const [currentLevel, setCurrentLevel] = useAtom(currentLevelAtom);
  const [currentScenario, setCurrentScenario] = useAtom(
    currentScenarioAtom
  );
  const [scenarioDropdown, setScenarioDropdown] = useAtom(
    dropdownToggleAtom
  );
  const [reference, setReference] = useAtom(systemCreferenceAtom);
  const [systemCConclusion, setSystemCConclusion] = useAtom(
    systemCConclusionAtom
  );
  const [currentLegendData, setCurrentLegendData] = React.useState({
    feedstock: currentData.climateData.datasets[0].data,
    electricity: currentData.climateData.datasets[1].data,
    directEmissions: currentData.climateData.datasets[2].data,
    catalyst: currentData.climateData.datasets[3].data,
    pdMembrane: currentData.climateData.datasets[4].data,
    sorbent: currentData.climateData.datasets[5].data,
    other: currentData.climateData.datasets[6].data,
  });

  const [currentLegendDataMaterial, setCurrentLegendDataMaterial] =
    React.useState({
      catalyst: currentDataMaterial.climateData.datasets[0].data,
      pdMembrane: currentDataMaterial.climateData.datasets[1].data,
      sorbent: currentDataMaterial.climateData.datasets[2].data,
    });

  const handleBarChart = (newBarChart) => {
    setCurrentBarChart(newBarChart);
  };
  React.useEffect(() => {
    if (currentScenario === "Base scenario") {
      if (reference && currentLevel === "systemLevel") {
        setCurrentData(referenceDataBase);
      } else if (currentLevel === "materialLevel") {
        setCurrentDataMaterial(dataBaseScenarioMaterialLevel);
      } else {
        setCurrentData(dataBaseScenario);
      }
    } else if (currentScenario === "Increased system efficiency") {
      if (reference) {
        setCurrentData(referenceDataIncreasedEfficiency);
      } else {
        setCurrentData(dataIncreasedEfficiency);
      }
    } else if (currentScenario === "Attrition Rate (sorbent)") {
      setCurrentDataMaterial(dataSorbentAttrition);
    } else if (currentScenario === "Attrition Rate (catalyst)") {
      setCurrentDataMaterial(dataCatalystAttrition);
    } else if (
      currentScenario === "Increased production efficiency (sorbent)"
    ) {
      setCurrentDataMaterial(dataEfficiencySorbent);
    } else if (
      currentScenario === "Increased production efficiency (catalyst)"
    ) {
      setCurrentDataMaterial(dataEfficiencyCatalyst);
    } else if (currentScenario === "Increased membrane lifetime") {
      setCurrentDataMaterial(dataMembrane);
    }
  }, [currentScenario, currentLevel, reference]);

  React.useEffect(() => {
    if (currentLevel === "systemLevel") {
      if (currentBarChart === "climate") {
        setCurrentLegendData({
          feedstock: currentData.climateData.datasets[0].data,
          electricity: currentData.climateData.datasets[1].data,
          directEmissions: currentData.climateData.datasets[2].data,
          catalyst: currentData.climateData.datasets[3].data,
          pdMembrane: currentData.climateData.datasets[4].data,
          sorbent: currentData.climateData.datasets[5].data,
          other: currentData.climateData.datasets[6].data,
        });
      } else if (currentBarChart === "humanhealth") {
        setCurrentLegendData({
          feedstock: currentData.humanHealthData.datasets[0].data,
          electricity: currentData.humanHealthData.datasets[1].data,
          directEmissions:
            currentData.humanHealthData.datasets[2].data,
          catalyst: currentData.humanHealthData.datasets[3].data,
          pdMembrane: currentData.humanHealthData.datasets[4].data,
          sorbent: currentData.humanHealthData.datasets[5].data,
          other: currentData.humanHealthData.datasets[6].data,
        });
      } else if (currentBarChart === "ecosystem_quality") {
        setCurrentLegendData({
          feedstock:
            currentData.ecosystemQualityData.datasets[0].data,
          electricity:
            currentData.ecosystemQualityData.datasets[1].data,
          directEmissions:
            currentData.ecosystemQualityData.datasets[2].data,
          catalyst: currentData.ecosystemQualityData.datasets[3].data,
          pdMembrane:
            currentData.ecosystemQualityData.datasets[4].data,
          sorbent: currentData.ecosystemQualityData.datasets[5].data,
          other: currentData.ecosystemQualityData.datasets[6].data,
        });
      } else if (currentBarChart === "resources_depletion") {
        setCurrentLegendData({
          feedstock:
            currentData.resourcesDepletionData.datasets[0].data,
          electricity:
            currentData.resourcesDepletionData.datasets[1].data,
          directEmissions:
            currentData.resourcesDepletionData.datasets[2].data,
          catalyst:
            currentData.resourcesDepletionData.datasets[3].data,
          pdMembrane:
            currentData.resourcesDepletionData.datasets[4].data,
          sorbent:
            currentData.resourcesDepletionData.datasets[5].data,
          other: currentData.resourcesDepletionData.datasets[6].data,
        });
      } else if (currentBarChart === "water_consumption") {
        setCurrentLegendData({
          feedstock:
            currentData.waterConsumptionData.datasets[0].data,
          electricity:
            currentData.waterConsumptionData.datasets[1].data,
          directEmissions:
            currentData.waterConsumptionData.datasets[2].data,
          catalyst: currentData.waterConsumptionData.datasets[3].data,
          pdMembrane:
            currentData.waterConsumptionData.datasets[4].data,
          sorbent: currentData.waterConsumptionData.datasets[5].data,
          other: currentData.waterConsumptionData.datasets[6].data,
        });
      }
    } else if (currentLevel === "materialLevel") {
      if (currentBarChart === "climate") {
        setCurrentLegendDataMaterial({
          catalyst: currentDataMaterial.climateData.datasets[0].data,
          pdMembrane:
            currentDataMaterial.climateData.datasets[1].data,
          sorbent: currentDataMaterial.climateData.datasets[2].data,
        });
      } else if (currentBarChart === "humanhealth") {
        setCurrentLegendDataMaterial({
          catalyst:
            currentDataMaterial.humanHealthData.datasets[0].data,
          pdMembrane:
            currentDataMaterial.humanHealthData.datasets[1].data,
          sorbent:
            currentDataMaterial.humanHealthData.datasets[2].data,
        });
      } else if (currentBarChart === "ecosystem_quality") {
        setCurrentLegendDataMaterial({
          catalyst:
            currentDataMaterial.ecosystemQualityData.datasets[0].data,
          pdMembrane:
            currentDataMaterial.ecosystemQualityData.datasets[1].data,
          sorbent:
            currentDataMaterial.ecosystemQualityData.datasets[2].data,
        });
      } else if (currentBarChart === "resources_depletion") {
        setCurrentLegendDataMaterial({
          catalyst:
            currentDataMaterial.resourcesDepletionData.datasets[0]
              .data,
          pdMembrane:
            currentDataMaterial.resourcesDepletionData.datasets[1]
              .data,
          sorbent:
            currentDataMaterial.resourcesDepletionData.datasets[2]
              .data,
        });
      } else if (currentBarChart === "water_consumption") {
        setCurrentLegendDataMaterial({
          catalyst:
            currentDataMaterial.waterConsumptionData.datasets[0].data,
          pdMembrane:
            currentDataMaterial.waterConsumptionData.datasets[1].data,
          sorbent:
            currentDataMaterial.waterConsumptionData.datasets[2].data,
        });
      }
    }
  }, [
    currentData,
    reference,
    currentBarChart,
    currentScenario,
    currentLevel,
  ]);

  React.useEffect(() => {
    if (currentLevel === "materialLevel") {
      setReference(false);
    }
  }, [currentLevel]);

  const toggle = () => {
    setReference(!reference);
  };

  return (
    <div className="w-full bg-primary-20 p-0 md:p-[60px] ">
      <BarChartNavigationSystemA handleBarChart={handleBarChart} />
      <div
        className="relative card-container mx-auto my-4 md:my-11 border-2 border-white rounded-3xl drop-shadow-4xl"
        style={{ maxWidth: "1024px" }}
      >
        <ChartInformation />

        <section className="w-[95%] h-fit mx-auto relative middleSection-container flex flex-col justify-center md:flex-row gap-4 ">
          <ConclusionC />
          {systemCConclusion && <ConclusionModalC />}

          <div className="flex flex-col w-full md:w-[50%] lg:w-[50%] h-[200px] mt-0 md:mt-4">
            <div className="w-full flex flex-row justify-between border-t-2 border-x-2 border-primary-10 rounded-t-md  ">
              <button
                className={`w-1/2 py-4  ${
                  currentLevel === "systemLevel"
                    ? "bg-primary-10"
                    : "bg-transparent"
                } font-bold text-sm `}
                onClick={() => {
                  setCurrentLevel("systemLevel");
                  setCurrentScenario("Base scenario");
                }}
              >
                SYSTEM LEVEL
              </button>
              <button
                className={`w-1/2 py-4 font-bold ${
                  currentLevel === "materialLevel"
                    ? "bg-primary-10"
                    : "bg-transparent"
                } text-sm `}
                onClick={() => {
                  setCurrentLevel("materialLevel");
                  setCurrentScenario("Base scenario");
                }}
              >
                MATERIAL LEVEL
              </button>
            </div>
            <div className="relative w-full flex flex-col justify-start bg-primary-10  rounded-b-md drop-shadow-4xl p-3 h-full">
              {currentLevel === "materialLevel" && (
                <p>
                  The material level displays the impacts of the key
                  materials for the system parameters.
                </p>
              )}
              <div className="w-[80%] drop-shadow-4xl absolute bottom-0 left-0">
                <div
                  className="w-full bg-white flex flex-row items-center justify-between rounded-t-md rounded-bl-md px-2 py-2"
                  style={{
                    boxShadow:
                      "0px 0px 24px rgba(188, 203, 218, 0.6)",
                  }}
                >
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setScenarioDropdown(!scenarioDropdown);
                    }}
                  >
                    <p className="opacity-[0.4] font-bold text-sm">
                      Scenario
                    </p>
                    <p className="font-bold text-md">
                      {currentScenario}
                    </p>
                  </div>
                  <img
                    src={dropdownVector}
                    alt=""
                    className="w-[11px] h-[7px] cursor-pointer"
                    onClick={() => {
                      setScenarioDropdown(!scenarioDropdown);
                    }}
                  />

                  {(() => {
                    switch (currentLevel) {
                      case "systemLevel":
                        return (
                          <div
                            className={` flex-col w-full z-50 ${
                              scenarioDropdown ? "flex" : "hidden"
                            } absolute top-[62px] left-0`}
                          >
                            <button
                              className="w-full py-4 bg-white "
                              onClick={() => {
                                setCurrentScenario("Base scenario");
                                setScenarioDropdown(false);
                              }}
                            >
                              Base Scenario
                            </button>
                            <button
                              className="w-full py-4 bg-white "
                              onClick={() => {
                                setCurrentScenario(
                                  "Increased system efficiency"
                                );
                                setScenarioDropdown(false);
                              }}
                            >
                              Increased system efficiency
                            </button>
                          </div>
                        );
                      case "materialLevel":
                        return (
                          <div
                            className={` flex-col w-full z-50 ${
                              scenarioDropdown ? "flex" : "hidden"
                            } absolute top-[62px] left-0`}
                          >
                            <button
                              className="w-full py-4 bg-white "
                              onClick={() => {
                                setCurrentScenario("Base scenario");
                                setScenarioDropdown(false);
                              }}
                            >
                              Base scenario
                            </button>
                            <button
                              className="w-full py-4 bg-white "
                              onClick={() => {
                                setCurrentScenario(
                                  "Attrition Rate (sorbent)"
                                );
                                setScenarioDropdown(false);
                              }}
                            >
                              Attrition Rate (sorbent)
                            </button>
                            <button
                              className="w-full py-4 bg-primary-10 text-md"
                              onClick={() => {
                                setCurrentScenario(
                                  "Attrition Rate (catalyst)"
                                );
                                setScenarioDropdown(false);
                              }}
                            >
                              Attrition Rate (catalyst)
                            </button>
                            <button
                              className="w-full py-4 bg-primary-10 text-md"
                              onClick={() => {
                                setCurrentScenario(
                                  "Increased production efficiency (sorbent)"
                                );
                                setScenarioDropdown(false);
                              }}
                            >
                              Increased production efficiency
                              (sorbent)
                            </button>
                            <button
                              className="w-full py-4 bg-primary-10 text-md"
                              onClick={() => {
                                setCurrentScenario(
                                  "Increased production efficiency (catalyst)"
                                );
                                setScenarioDropdown(false);
                              }}
                            >
                              Increased production efficiency
                              (catalyst)
                            </button>
                            <button
                              className="w-full py-4 bg-primary-10 text-md"
                              onClick={() => {
                                setCurrentScenario(
                                  "Increased membrane lifetime"
                                );
                                setScenarioDropdown(false);
                              }}
                            >
                              Increased membrane lifetime
                            </button>
                          </div>
                        );
                      default:
                        return null;
                    }
                  })()}
                </div>
              </div>
            </div>
          </div>
        </section>

        {currentLevel === "systemLevel" && (
          <div className=" flex flex-row w-full justify-end items-center ">
            <button
              className="w-fit py-4 px-2 font-bold text-sm flex flex-row justify-end items-center "
              onClick={toggle}
            >
              Reference System{" "}
              <div className="w-5 h-5 border-4 rounded-full border-primary-10 bg-primary-10 mx-4 flex flex-row justify-center items-center">
                <img src={reference ? subtract : add} alt="" />
              </div>
            </button>
          </div>
        )}

        <div
          className={`relative ${
            currentLevel === "materialLevel" ? "mt-11" : "mt-11"
          }`}
          style={{
            width: "100%",
            height: "50%",
            padding: "0px 20px",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          {currentLevel === "systemLevel" &&
            (() => {
              switch (currentBarChart) {
                case "climate":
                  return (
                    <div>
                      <BarChart
                        data={currentData.climateData}
                        options={systemCclimateChangeOptions}
                      />
                    </div>
                  );
                case "humanhealth":
                  return (
                    <BarChart
                      data={currentData.humanHealthData}
                      options={systemChumanHealthOptions}
                    />
                  );
                case "ecosystem_quality":
                  return (
                    <BarChart
                      data={currentData.ecosystemQualityData}
                      options={systemCecosystemQualityOptions}
                    />
                  );
                case "resources_depletion":
                  return (
                    <BarChart
                      data={currentData.resourcesDepletionData}
                      options={systemCresourcesDepletionOptions}
                    />
                  );
                case "water_consumption":
                  return (
                    <BarChart
                      data={currentData.waterConsumptionData}
                      options={
                        reference
                          ? systemCwaterConsumptionReferenceOptions
                          : systemCwaterConsumptionOptions
                      }
                    />
                  );
                default:
                  return null;
              }
            })()}

          {currentLevel === "materialLevel" &&
            (() => {
              switch (currentBarChart) {
                case "climate":
                  return (
                    <div>
                      <BarChart
                        data={currentDataMaterial.climateData}
                        options={climateChangeOptionsBase}
                      />
                    </div>
                  );
                case "humanhealth":
                  return (
                    <BarChart
                      data={currentDataMaterial.humanHealthData}
                      options={systemChumanHealthOptionsBase}
                    />
                  );
                case "ecosystem_quality":
                  return (
                    <BarChart
                      data={currentDataMaterial.ecosystemQualityData}
                      options={systemCecosystemQualityMaterialOptions}
                    />
                  );
                case "resources_depletion":
                  return (
                    <BarChart
                      data={
                        currentDataMaterial.resourcesDepletionData
                      }
                      options={
                        systemCresourcesDepletionMaterialOptions
                      }
                    />
                  );
                case "water_consumption":
                  return (
                    <BarChart
                      data={currentDataMaterial.waterConsumptionData}
                      options={
                        systemCwaterConsumptionMaterialLevelOptions
                      }
                    />
                  );
                default:
                  return null;
              }
            })()}

          <LegendC
            currentLegendData={currentLegendData}
            currentLegendDataMaterial={currentLegendDataMaterial}
          />

          {reference && (
            <div className=" w-full px-4 py-1 lg:w-[210px] bg-primary-10 rounded-md drop-shadow-4xl mr-4 mt-4 lg:mt-0 mb-4 lg:mb-0 relative md:relative lg:absolute lg:bottom-2 lg:right-0">
              <p className="text-xs text-center">
                The results have not been reviewed according to ISO
                14040/44 standards
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SystemC;
