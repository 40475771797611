import React from "react";
import { atom, useAtom } from "jotai";
import { referenceAtom, currentBarChartAtom } from "./SystemA.jsx";
import {
  systemBClimateChangeOptions,
  systemBhumanHealthOptions,
  systemBhumanHealthReferenceOptions,
  systemBecosystemQualityOptions,
  systemBresourcesDepletionOptions,
  systemBwaterConsumptionOptions,
  climateReferenceOptions,
  systemBecosystemQualityReferenceOptions,
  systemBresourcesDepletionReferenceOptions,
  systemBwaterConsumptionReferenceOptions,
} from "../common/shared.js";
import BarChart from "./BarChart.js";
import LegendB from "./LegendB.js";
import ChartInformation from "./ChartInformation.js";
import add from "../assets/add.png";
import subtract from "../assets/subtract.png";
import {
  dataCase1,
  dataCase2,
  dataCase3,
  dataCase4,
  referenceDataCase1,
  referenceDataCase2,
  referenceDataCase3,
  referenceDataCase4,
} from "../data/systemB.js";
import BarChartNavigationSystemA from "./BarChartNavigationSystemA.js";
import Parameters from "./Parameters.js";
import ConclusionB from "./ConclusionB.js";
import ConclusionModalB from "./ConclusionModalB.js";

export const systemBcurrentDataAtom = atom(dataCase1);
export const systemBcurrentCaseAtom = atom(1);
export const systemBConclusionAtom = atom(false);

export default function SystemA() {
  const [currentData, setCurrentData] = useAtom(
    systemBcurrentDataAtom
  );
  const [currentBarChart, setCurrentBarChart] = useAtom(
    currentBarChartAtom
  );
  const [currentCase, setCurrentCase] = useAtom(
    systemBcurrentCaseAtom
  );
  const [currentLegendData, setCurrentLegendData] = React.useState({
    memberane: currentData.climateData.datasets[0].data,
    coalAsh: currentData.climateData.datasets[1].data,
    pbi: currentData.climateData.datasets[2].data,
    hardCoal: currentData.climateData.datasets[3].data,
    directEmissions: currentData.climateData.datasets[4].data,
  });
  const [reference, setReference] = useAtom(referenceAtom);
  const [systemBConclusion, setSystemBConclusion] = useAtom(
    systemBConclusionAtom
  );

  React.useEffect(() => {
    if (reference === false) {
      if (currentCase === 1) {
        setCurrentData(dataCase1);
      } else if (currentCase === 2) {
        setCurrentData(dataCase2);
      } else if (currentCase === 3) {
        setCurrentData(dataCase3);
      } else if (currentCase === 4) {
        setCurrentData(dataCase4);
      }
    } else if (reference) {
      if (currentCase === 1) {
        setCurrentData(referenceDataCase1);
      } else if (currentCase === 2) {
        setCurrentData(referenceDataCase2);
      } else if (currentCase === 3) {
        setCurrentData(referenceDataCase3);
      } else if (currentCase === 4) {
        setCurrentData(referenceDataCase4);
      }
    }
  }, [reference, currentCase]);

  React.useEffect(() => {
    if (currentBarChart === "climate") {
      setCurrentLegendData({
        memberane: currentData.climateData.datasets[0].data,
        coalAsh: currentData.climateData.datasets[1].data,
        pbi: currentData.climateData.datasets[2].data,
        hardCoal: currentData.climateData.datasets[3].data,
        directEmissions: currentData.climateData.datasets[4].data,
      });
    } else if (currentBarChart === "humanhealth") {
      setCurrentLegendData({
        memberane: currentData.humanHealthData.datasets[0].data,
        coalAsh: currentData.humanHealthData.datasets[1].data,
        pbi: currentData.humanHealthData.datasets[2].data,
        hardCoal: currentData.humanHealthData.datasets[3].data,
        directEmissions: currentData.humanHealthData.datasets[4].data,
      });
    } else if (currentBarChart === "ecosystem_quality") {
      setCurrentLegendData({
        memberane: currentData.ecosystemQualityData.datasets[0].data,
        coalAsh: currentData.ecosystemQualityData.datasets[1].data,
        pbi: currentData.ecosystemQualityData.datasets[2].data,
        hardCoal: currentData.ecosystemQualityData.datasets[3].data,
        directEmissions:
          currentData.ecosystemQualityData.datasets[4].data,
      });
    } else if (currentBarChart === "resources_depletion") {
      setCurrentLegendData({
        memberane:
          currentData.resourcesDepletionData.datasets[0].data,
        coalAsh: currentData.resourcesDepletionData.datasets[1].data,
        pbi: currentData.resourcesDepletionData.datasets[2].data,
        hardCoal: currentData.resourcesDepletionData.datasets[3].data,
        directEmissions:
          currentData.resourcesDepletionData.datasets[4].data,
      });
    } else if (currentBarChart === "water_consumption") {
      setCurrentLegendData({
        memberane: currentData.waterConsumptionData.datasets[0].data,
        coalAsh: currentData.waterConsumptionData.datasets[1].data,
        pbi: currentData.waterConsumptionData.datasets[2].data,
        hardCoal: currentData.waterConsumptionData.datasets[3].data,
        directEmissions:
          currentData.waterConsumptionData.datasets[4].data,
      });
    }
  }, [currentData, reference, currentBarChart, currentCase]);

  const handleBarChart = (newBarChart) => {
    setCurrentBarChart(newBarChart);
  };

  const toggle = () => {
    setReference(!reference);
  };

  return (
    <div className="w-full bg-primary-20 px-0 md:px-[60px]">
      <BarChartNavigationSystemA handleBarChart={handleBarChart} />
      <div
        className="card-container mx-auto my-4 md:my-11 border-2 border-white rounded-3xl drop-shadow-4xl"
        style={{ maxWidth: "1024px" }}
      >
        <ChartInformation />
        <section className="w-[95%] h-fit mx-auto relative middleSection-container flex flex-col justify-center md:flex-row gap-4 ">
          <ConclusionB />
          {systemBConclusion && <ConclusionModalB />}

          <div className="flex flex-col w-full h-[200px] md:w-[50%] lg:w-[50%] mt-0 md:mt-4">
            <div className="w-full flex flex-row justify-between border-t-2 border-x-2 border-primary-10 rounded-t-md  ">
              <button
                className={`w-1/4 py-4  ${
                  currentCase === 1
                    ? "bg-primary-10"
                    : "bg-transparent"
                } font-bold text-sm `}
                onClick={() => {
                  setCurrentCase(1);
                }}
              >
                CASE 1
              </button>
              <button
                className={`w-1/4 py-4 font-bold ${
                  currentCase === 2
                    ? "bg-primary-10"
                    : "bg-transparent"
                } text-sm `}
                onClick={() => {
                  setCurrentCase(2);
                }}
              >
                CASE 2
              </button>
              <button
                className={`w-1/4 py-4 font-bold ${
                  currentCase === 3
                    ? "bg-primary-10"
                    : "bg-transparent"
                } text-sm `}
                onClick={() => {
                  setCurrentCase(3);
                }}
              >
                CASE 3
              </button>
              <button
                className={`w-1/4 py-4 font-bold ${
                  currentCase === 4
                    ? "bg-primary-10"
                    : "bg-transparent"
                } text-sm `}
                onClick={() => {
                  setCurrentCase(4);
                }}
              >
                CASE 4
              </button>
            </div>
            <div className=" flex flex-col justify-start bg-primary-10  rounded-b-md drop-shadow-4xl p-3">
              <p className="text-xl font-bold ">
                Process parameters:
              </p>

              <Parameters system="systemB" />
            </div>
          </div>
        </section>
        <div className="flex flex-row w-full justify-end items-center ">
          <button
            className="w-fit py-4 px-2 font-bold text-sm flex flex-row justify-end items-center "
            onClick={toggle}
          >
            Reference System{" "}
            <div className="w-5 h-5 border-4 rounded-full border-primary-10 bg-primary-10 mx-4 flex flex-row justify-center items-center">
              <img src={reference ? subtract : add} alt="" />
            </div>
          </button>
        </div>
        <div
          className="relative"
          style={{
            width: "100%",
            height: "50%",
            padding: "0px 20px",
            marginRight: "auto",
            marginLeft: "auto",
          }}
        >
          {!reference &&
            (() => {
              switch (currentBarChart) {
                case "climate":
                  return (
                    <BarChart
                      data={currentData.climateData}
                      options={systemBClimateChangeOptions}
                    />
                  );
                case "humanhealth":
                  return (
                    <BarChart
                      data={currentData.humanHealthData}
                      options={systemBhumanHealthOptions}
                    />
                  );
                case "ecosystem_quality":
                  return (
                    <BarChart
                      data={currentData.ecosystemQualityData}
                      options={systemBecosystemQualityOptions}
                    />
                  );
                case "resources_depletion":
                  return (
                    <BarChart
                      data={currentData.resourcesDepletionData}
                      options={systemBresourcesDepletionOptions}
                    />
                  );
                case "water_consumption":
                  return (
                    <BarChart
                      data={currentData.waterConsumptionData}
                      options={systemBwaterConsumptionOptions}
                    />
                  );
                case "climate_reference":
                  return (
                    <BarChart
                      data={climateDataWithReferenceCase1}
                      options={referenceOptions}
                    />
                  );
                default:
                  return null;
              }
            })()}
          {reference &&
            (() => {
              switch (currentBarChart) {
                case "climate":
                  return (
                    <BarChart
                      data={currentData.climateData}
                      options={climateReferenceOptions}
                    />
                  );
                case "humanhealth":
                  return (
                    <BarChart
                      data={currentData.humanHealthData}
                      options={systemBhumanHealthReferenceOptions}
                    />
                  );
                case "ecosystem_quality":
                  return (
                    <BarChart
                      data={currentData.ecosystemQualityData}
                      options={
                        systemBecosystemQualityReferenceOptions
                      }
                    />
                  );
                case "resources_depletion":
                  return (
                    <BarChart
                      data={currentData.resourcesDepletionData}
                      options={
                        systemBresourcesDepletionReferenceOptions
                      }
                    />
                  );
                case "water_consumption":
                  return (
                    <BarChart
                      data={currentData.waterConsumptionData}
                      options={
                        systemBwaterConsumptionReferenceOptions
                      }
                    />
                  );
                case "climate_reference":
                  return (
                    <BarChart
                      data={climateDataWithReferenceCase1}
                      options={referenceOptions}
                    />
                  );
                default:
                  return null;
              }
            })()}
          <LegendB
            currentLegendData={currentLegendData}
            currentBarChart={currentBarChart}
          />
          {reference && (
            <div className=" w-full px-4 lg:w-[210px] bg-primary-10 rounded-md drop-shadow-4xl p-3 mr-4 mt-4 lg:mt-0 mb-4 lg:mb-0 relative md:relative lg:absolute lg:bottom-8 lg:right-0">
              <p className="text-xs text-center">
                The results have not been reviewed according to ISO
                14040/44 standards
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
