import React from "react";
import { useAtom } from "jotai";
import { systemAConclusionAtom } from "./SystemA";

function ConclusionModal(props) {
  const [systemAConclusion, setSystemAConclusion] = useAtom(
    systemAConclusionAtom
  );

  return (
    <div className="absolute left-0 top-2 z-40 bg-primary-10 w-[100%] md:w-1/2 h-fit p-11 rounded-md drop-shadow-4xl">
      <p className="font-bold">Conclusion</p>
      <p className="my-4">
        The impact categories <strong>climate change</strong>,{" "}
        <strong>ecosystem quality</strong> and resources are dominated
        by the feedstock (coal) of the system which reflects the
        additional energy needed for pre-combustion CO₂ capture with
        the <strong>MEMBER membrane (PBI)</strong> . The impacts for{" "}
        <strong>climate change</strong> are related to energy
        intensive upstream processes which occur during mining and
        processing activities. The e<strong>ecosystem quality</strong>{" "}
        is influenced by emissions to air and soil in the mining
        processes. The <strong>resource</strong> indicator reflects
        the depletion of mineral and fossil resources, which is why
        coal is the main contributor. <strong>Human health</strong>{" "}
        impacts are closely related to other emissions than CO₂ in the
        coal combustion process as well as residual coal ash disposal.{" "}
        <strong>Water consumption</strong> is related to direct and
        indirect (e.g., energy in materials production) cooling
        processes and thus also directly linked to the additional
        power demand for the capture system.
      </p>
      <p
        className=" cursor-pointer text-right text-base font-bold underline "
        onClick={() => {
          setSystemAConclusion(false);
        }}
      >
        READ LESS{" "}
      </p>
    </div>
  );
}

export default ConclusionModal;
