//common
export const systemBCaseParameters = {
  case1: {
    totalEnergy: 135,
    recoveryRate: "91%",
    memberaneArea: 6.85,
  },
  case2: {
    totalEnergy: 152,
    recoveryRate: "90%",
    memberaneArea: 1.4,
  },
  case3: {
    totalEnergy: 158,
    recoveryRate: "90%",
    memberaneArea: 4.55,
  },
  case4: {
    totalEnergy: 137,
    recoveryRate: "91%",
    memberaneArea: 2.75,
  },
};

const labels = ["", ""];

export const climateReferenceData = [
  {
    label: "Material and Waste",
    data: [0.0568138633],
    backgroundColor: "#FF489C",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Feedstock Production",
    data: [0.032165825],
    backgroundColor: "#FFC136",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Direct Emissions",
    data: [0.592713282],
    backgroundColor: "#7045EC",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
];

export const humanHealthReferenceData = [
  {
    label: "Material and Waste",
    data: [0.00000006954904186],
    backgroundColor: "#FF489C",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Feedstock Production",
    data: [0],
    backgroundColor: "#FFC136",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Direct Emissions",
    data: [0.0000002887105447],
    backgroundColor: "#7045EC",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
];

export const ecosystemQualityReferenceData = [
  {
    label: "Material and Waste",
    data: [0.01198269738],
    backgroundColor: "#FF489C",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Feedstock Production",
    data: [0],
    backgroundColor: "#FFC136",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Direct Emissions",
    data: [0.08977454676],
    backgroundColor: "#7045EC",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
];

export const resourcesDepletionReferenceData = [
  {
    label: "Material and Waste",
    data: [0.5879902667],
    backgroundColor: "#FF489C",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Feedstock Production",
    data: [0],
    backgroundColor: "#FFC136",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Direct Emissions",
    data: [12.69028542],
    backgroundColor: "#7045EC",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
];

export const waterConsumptionReferenceData = [
  {
    label: "Material and Waste",
    data: [0.0001539039293],
    backgroundColor: "#FF489C",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Feedstock Production",
    data: [0.000193],
    backgroundColor: "#FFC136",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Direct Emissions",
    data: [0.0007335823044],
    backgroundColor: "#7045EC",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
];

export const placeholderData = [
  {
    label: "blank",
    data: [0.031054671],
    backgroundColor: "transparent",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 2",
  },
  {
    label: "blank",
    data: [0.057236078],
    backgroundColor: "transparent ",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 2",
  },
  {
    label: "blank",
    data: [0.006287824],
    backgroundColor: "transparent ",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 2",
  },
];

export const totalData = {
  case1: {
    climate: 0.081055162,
    humanHealth: 0.000000073643515,
    ecosystemQuality: 0.01436767,
    resourcesDepletion: 3.4996474,
    waterConsumption: 0.00034119921,
  },
  case2: {
    climate: 0.09371866,
    humanHealth: 0.00000008416496,
    ecosystemQuality: 0.014899571,
    resourcesDepletion: 4.0827296,
    waterConsumption: 0.00032685774,
  },
  case3: {
    climate: 0.10108587,
    humanHealth: 0.000000090397756,
    ecosystemQuality: 0.016764976,
    resourcesDepletion: 4.3434678,
    waterConsumption: 0.00038259737,
  },
  case4: {
    climate: 0.079899946,
    humanHealth: 0.000000073108394,
    ecosystemQuality: 0.013334365,
    resourcesDepletion: 3.5249648,
    waterConsumption: 0.000300365,
  },
  reference: {
    climate: 0.6816929703,
    humanHealth: 0.000000358259587,
    ecosystemQuality: 0.10175724414,
    resourcesDepletion: 13.2782756867,
    waterConsumption: 0.00108048623,
  },
};
//----CASE 1

export const climateDataCase1 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.0014178332],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.00013265857],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PEBAX membrane",
      data: [0.0030393599],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal",
      data: [0.053468755],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.0229965546],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const humanHealthDataCase1 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.00000000043943945],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.000000015405829],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PEBAX",
      data: [0.0000000027784211],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [0.000000013220862],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.000000041798964],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const ecosystemQualityDataCase1 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.00019981543],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.00054831887],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PEBAX",
      data: [0.0019300157],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [0.0082458866],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.0034436336],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const resourcesDepletionDataCase1 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.0073427465],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.0043776393],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PEBAX",
      data: [0.063569535],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [3.4016594],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.022698114],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const waterConsumptionDataCase1 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.0000049220553],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.00000032905993],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PEBAX",
      data: [0.000071613734],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [0.000061816662],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.0002025177],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const climateDataWithReferenceCase1 = {
  labels: [""],
  datasets: [
    ...climateDataCase1.datasets,
    ...climateReferenceData,
    ...placeholderData,
  ],
};

export const humanHealthDataWithReferenceCase1 = {
  labels: [""],
  datasets: [
    ...humanHealthDataCase1.datasets,
    ...humanHealthReferenceData,
    ...placeholderData,
  ],
};

export const ecosystemQualityDataWithReferenceCase1 = {
  labels: [""],
  datasets: [
    ...ecosystemQualityDataCase1.datasets,
    ...ecosystemQualityReferenceData,
    ...placeholderData,
  ],
};

export const resourcesDepletionDataWithReferenceCase1 = {
  labels: [""],
  datasets: [
    ...resourcesDepletionDataCase1.datasets,
    ...resourcesDepletionReferenceData,
    ...placeholderData,
  ],
};

export const waterConsumptionDataWithReferenceCase1 = {
  labels: [""],
  datasets: [
    ...waterConsumptionDataCase1.datasets,
    ...waterConsumptionReferenceData,
    ...placeholderData,
  ],
};

//----CASE 2

export const climateDataCase2 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.00024744349],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.00015747409],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PEBAX",
      data: [0.00053043605],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal",
      data: [0.063474359],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.0293089472],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const humanHealthDataCase2 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.000000018287691],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PEBAX",
      data: [0.00000000048489642],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [0.000000015694881],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.000000049620801],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const ecosystemQualityDataCase2 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.000034872246],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.00065088909],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PEBAX",
      data: [0.00033683077],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [0.0097889387],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.0040880405],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const resourcesDepletionDataCase2 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.001281473],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.005196534],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PEBAX",
      data: [0.011094301],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [4.0382117],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.02694561],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const waterConsumptionDataCase2 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.00000085900838],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.00000039061489],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PEBAX",
      data: [0.000012498193],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [0.000073384409],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.00023972551],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const climateDataWithReferenceCase2 = {
  labels: [""],
  datasets: [
    ...climateDataCase2.datasets,
    ...climateReferenceData,
    ...placeholderData,
  ],
};

export const humanHealthDataWithReferenceCase2 = {
  labels: [""],
  datasets: [
    ...humanHealthDataCase2.datasets,
    ...humanHealthReferenceData,
    ...placeholderData,
  ],
};

export const ecosystemQualityDataWithReferenceCase2 = {
  labels: [""],
  datasets: [
    ...ecosystemQualityDataCase2.datasets,
    ...ecosystemQualityReferenceData,
    ...placeholderData,
  ],
};

export const resourcesDepletionDataWithReferenceCase2 = {
  labels: [""],
  datasets: [
    ...resourcesDepletionDataCase2.datasets,
    ...resourcesDepletionReferenceData,
    ...placeholderData,
  ],
};

export const waterConsumptionDataWithReferenceCase2 = {
  labels: [""],
  datasets: [
    ...waterConsumptionDataCase2.datasets,
    ...waterConsumptionReferenceData,
    ...placeholderData,
  ],
};

//----CASE 3

export const climateDataCase3 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.00095365083],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.00016619533],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PEBAX",
      data: [0.0020443083],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal",
      data: [0.06698959],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.0309321224],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const humanHealthDataCase3 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.00000000029557201],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.000000019300501],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PEBAX",
      data: [0.0000000018687979],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [0.000000016564068],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.000000052368817],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const ecosystemQualityDataCase3 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.00013439815],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.00068693667],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PEBAX",
      data: [0.0012981507],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [0.010331053],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.0043144375],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const resourcesDepletionDataCase3 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.0049388155],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.0054843288],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PEBAX",
      data: [0.042757598],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [4.2618492],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.028437867],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const waterConsumptionDataCase3 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.0000033106309],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.00000041224795],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PEBAX",
      data: [0.000048168219],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [0.000077448461],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.0002532578],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const climateDataWithReferenceCase3 = {
  labels: [""],
  datasets: [
    ...climateDataCase3.datasets,
    ...climateReferenceData,
    ...placeholderData,
  ],
};

export const humanHealthDataWithReferenceCase3 = {
  labels: [""],
  datasets: [
    ...humanHealthDataCase3.datasets,
    ...humanHealthReferenceData,
    ...placeholderData,
  ],
};

export const ecosystemQualityDataWithReferenceCase3 = {
  labels: [""],
  datasets: [
    ...ecosystemQualityDataCase3.datasets,
    ...ecosystemQualityReferenceData,
    ...placeholderData,
  ],
};

export const resourcesDepletionDataWithReferenceCase3 = {
  labels: [""],
  datasets: [
    ...resourcesDepletionDataCase3.datasets,
    ...resourcesDepletionReferenceData,
    ...placeholderData,
  ],
};

export const waterConsumptionDataWithReferenceCase3 = {
  labels: [""],
  datasets: [
    ...waterConsumptionDataCase3.datasets,
    ...waterConsumptionReferenceData,
    ...placeholderData,
  ],
};

//----CASE 4

export const climateDataCase4 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.00056893942],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.00013527577],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PEBAX",
      data: [0.0012196157],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal",
      data: [0.054525653],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.0234504629],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const humanHealthDataCase4 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.00000000017633558],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.000000015709768],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PEBAX",
      data: [0.0000000011149078],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [0.000000013482194],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.000000042625189],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const ecosystemQualityDataCase4 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.000080180712],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.00055913656],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PEBAX",
      data: [0.0007744649],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [0.00840888],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.0035117026],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const resourcesDepletionDataCase4 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.0029464524],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.004464005],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PEBAX",
      data: [0.025508794],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [3.4688988],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.023146779],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const waterConsumptionDataCase4 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.0000019750923],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.0000003355519],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PEBAX",
      data: [0.000028736721],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [0.000063038569],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.00020627906],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const climateDataWithReferenceCase4 = {
  labels: [""],
  datasets: [
    ...climateDataCase4.datasets,
    ...climateReferenceData,
    ...placeholderData,
  ],
};

export const humanHealthDataWithReferenceCase4 = {
  labels: [""],
  datasets: [
    ...humanHealthDataCase4.datasets,
    ...humanHealthReferenceData,
    ...placeholderData,
  ],
};

export const ecosystemQualityDataWithReferenceCase4 = {
  labels: [""],
  datasets: [
    ...ecosystemQualityDataCase4.datasets,
    ...ecosystemQualityReferenceData,
    ...placeholderData,
  ],
};

export const resourcesDepletionDataWithReferenceCase4 = {
  labels: [""],
  datasets: [
    ...resourcesDepletionDataCase4.datasets,
    ...resourcesDepletionReferenceData,
    ...placeholderData,
  ],
};

export const waterConsumptionDataWithReferenceCase4 = {
  labels: [""],
  datasets: [
    ...waterConsumptionDataCase4.datasets,
    ...waterConsumptionReferenceData,
    ...placeholderData,
  ],
};

//REFERENCE DATA

export const dataCase1 = {
  climateData: climateDataCase1,
  humanHealthData: humanHealthDataCase1,
  ecosystemQualityData: ecosystemQualityDataCase1,
  resourcesDepletionData: resourcesDepletionDataCase1,
  waterConsumptionData: waterConsumptionDataCase1,
};

export const dataCase2 = {
  climateData: climateDataCase2,
  humanHealthData: humanHealthDataCase2,
  ecosystemQualityData: ecosystemQualityDataCase2,
  resourcesDepletionData: resourcesDepletionDataCase2,
  waterConsumptionData: waterConsumptionDataCase2,
};

export const dataCase3 = {
  climateData: climateDataCase3,
  humanHealthData: humanHealthDataCase3,
  ecosystemQualityData: ecosystemQualityDataCase3,
  resourcesDepletionData: resourcesDepletionDataCase3,
  waterConsumptionData: waterConsumptionDataCase3,
};

export const dataCase4 = {
  climateData: climateDataCase4,
  humanHealthData: humanHealthDataCase4,
  ecosystemQualityData: ecosystemQualityDataCase4,
  resourcesDepletionData: resourcesDepletionDataCase4,
  waterConsumptionData: waterConsumptionDataCase4,
};

export const referenceDataCase1 = {
  climateData: climateDataWithReferenceCase1,
  humanHealthData: humanHealthDataWithReferenceCase1,
  ecosystemQualityData: ecosystemQualityDataWithReferenceCase1,
  resourcesDepletionData: resourcesDepletionDataWithReferenceCase1,
  waterConsumptionData: waterConsumptionDataWithReferenceCase1,
};

export const referenceDataCase2 = {
  climateData: climateDataWithReferenceCase2,
  humanHealthData: humanHealthDataWithReferenceCase2,
  ecosystemQualityData: ecosystemQualityDataWithReferenceCase2,
  resourcesDepletionData: resourcesDepletionDataWithReferenceCase2,
  waterConsumptionData: waterConsumptionDataWithReferenceCase2,
};

export const referenceDataCase3 = {
  climateData: climateDataWithReferenceCase3,
  humanHealthData: humanHealthDataWithReferenceCase3,
  ecosystemQualityData: ecosystemQualityDataWithReferenceCase3,
  resourcesDepletionData: resourcesDepletionDataWithReferenceCase3,
  waterConsumptionData: waterConsumptionDataWithReferenceCase3,
};

export const referenceDataCase4 = {
  climateData: climateDataWithReferenceCase4,
  humanHealthData: humanHealthDataWithReferenceCase4,
  ecosystemQualityData: ecosystemQualityDataWithReferenceCase4,
  resourcesDepletionData: resourcesDepletionDataWithReferenceCase4,
  waterConsumptionData: waterConsumptionDataWithReferenceCase4,
};
