import "./App.css";

import SystemA from "./components/SystemA";
import SystemB from "./components/SystemB";
import SystemC from "./components/SystemC";
import Introduction from "./components/Introduction";
import Communicate from "./components/Communicate";
import PreCO2Divider from "./components/PreCO2Divider";
import PostCO2Divider from "./components/PostCO2Divider";
import H2ProductionDivider from "./components/H2ProductionDivider";
import Members from "./components/Members";
import Footer from "./components/Footer";

function App() {
  return (
    <div id="home" className="bg-cover w-full bg-primary-20">
      <Introduction />
      <Communicate />
      <PreCO2Divider />
      <SystemA />
      <PostCO2Divider />
      <SystemB />
      <H2ProductionDivider />
      <SystemC />
      <Members />
      <Footer />
    </div>
  );
}

export default App;
