import React from "react";
import { atom, useAtom } from "jotai";
import {
  systemCreferenceAtom,
  currentScenarioAtom,
  currentLevelAtom,
} from "./SystemC";
import { currentBarChartAtom } from "./SystemA";
import {
  climateReferenceData,
  humanHealthReferenceData,
  ecosystemQualityReferenceData,
  resourcesDepletionReferenceData,
  waterConsumptionReferenceData,
  totalData,
} from "../data/systemC";
import { dataUnits } from "../common/shared";

const currentUnitsAtom = atom("kg CO₂-eq");

function LegendC(props) {
  let feedstockNum = props.currentLegendData.feedstock[0];
  let electricityNum = props.currentLegendData.electricity[0];
  let directemissionsNum = props.currentLegendData.directEmissions[0];
  let catalystNum = props.currentLegendData.catalyst[0];
  let pdmembraneNum = props.currentLegendData.pdMembrane[0];
  let sorbentNum = props.currentLegendData.sorbent[0];
  let otherNum = props.currentLegendData.other[0];

  let catalystMaterialNum =
    props.currentLegendDataMaterial.catalyst[0];
  let pdmembraneMaterialNum =
    props.currentLegendDataMaterial.pdMembrane[0];
  let sorbentMaterialNum = props.currentLegendDataMaterial.sorbent[0];

  const [reference] = useAtom(systemCreferenceAtom);
  const [currentLevel] = useAtom(currentLevelAtom);
  const [currentScenario] = useAtom(currentScenarioAtom);
  const [currentBarChart] = useAtom(currentBarChartAtom);
  const [referenceLegendData, setReferenceLegendData] =
    React.useState({
      feedstock: climateReferenceData[0].data,
      electricity: climateReferenceData[1].data,
      directEmissions: climateReferenceData[2].data,
      catalyst: climateReferenceData[3].data,
      other: climateReferenceData[4].data,
    });
  const [currentUnits, setCurrentUnits] = useAtom(currentUnitsAtom);
  const [currentTotal, setCurrentTotal] = React.useState(
    totalData.base.climate
  );
  const [referenceTotal, setReferenceTotal] = React.useState(
    totalData.reference.climate
  );

  React.useEffect(() => {
    if (currentBarChart === "climate") {
      setReferenceLegendData({
        feedstock: climateReferenceData[0].data,
        electricity: climateReferenceData[1].data,
        directEmissions: climateReferenceData[2].data,
        catalyst: climateReferenceData[3].data,
        other: climateReferenceData[4].data,
      });
      setCurrentUnits(dataUnits.climate);
      setReferenceTotal(totalData.reference.climate);
      if (currentScenario === "Base scenario") {
        setCurrentTotal(totalData.base.climate);
      } else if (currentScenario === "Increased system efficiency") {
        setCurrentTotal(totalData.increasedEfficiency.climate);
      } else if (currentScenario === "Attrition Rate (sorbent)") {
        setCurrentTotal(totalData.sorbentAttrition.climate);
      } else if (currentScenario === "Attrition Rate (catalyst)") {
        setCurrentTotal(totalData.catalystAttrition.climate);
      } else if (
        currentScenario ===
        "Increased production efficiency (sorbent)"
      ) {
        setCurrentTotal(totalData.efficiencySorbent.climate);
      } else if (
        currentScenario ===
        "Increased production efficiency (catalyst)"
      ) {
        setCurrentTotal(totalData.efficiencyCatalyst.climate);
      } else if (currentScenario === "Increased membrane lifetime") {
        setCurrentTotal(totalData.membrane.climate);
      }
    } else if (currentBarChart === "humanhealth") {
      setReferenceLegendData({
        feedstock: humanHealthReferenceData[0].data,
        electricity: humanHealthReferenceData[1].data,
        directEmissions: humanHealthReferenceData[2].data,
        catalyst: humanHealthReferenceData[3].data,
        other: humanHealthReferenceData[4].data,
      });
      setCurrentUnits(dataUnits.humanHealth);
      setReferenceTotal(totalData.reference.humanHealth);
      if (currentScenario === "Base scenario") {
        setCurrentTotal(totalData.base.humanHealth);
      } else if (currentScenario === "Increased system efficiency") {
        setCurrentTotal(totalData.increasedEfficiency.humanHealth);
      } else if (currentScenario === "Attrition Rate (sorbent)") {
        setCurrentTotal(totalData.sorbentAttrition.humanHealth);
      } else if (currentScenario === "Attrition Rate (catalyst)") {
        setCurrentTotal(totalData.catalystAttrition.humanHealth);
      } else if (
        currentScenario ===
        "Increased production efficiency (sorbent)"
      ) {
        setCurrentTotal(totalData.efficiencySorbent.humanHealth);
      } else if (
        currentScenario ===
        "Increased production efficiency (catalyst)"
      ) {
        setCurrentTotal(totalData.efficiencyCatalyst.humanHealth);
      } else if (currentScenario === "Increased membrane lifetime") {
        setCurrentTotal(totalData.membrane.humanHealth);
      }
    } else if (currentBarChart === "ecosystem_quality") {
      setReferenceLegendData({
        feedstock: ecosystemQualityReferenceData[0].data,
        electricity: ecosystemQualityReferenceData[1].data,
        directEmissions: ecosystemQualityReferenceData[2].data,
        catalyst: ecosystemQualityReferenceData[3].data,
        other: ecosystemQualityReferenceData[4].data,
      });
      setCurrentUnits(dataUnits.ecosystemQuality);
      setReferenceTotal(totalData.reference.ecosystemQuality);
      if (currentScenario === "Base scenario") {
        setCurrentTotal(totalData.base.ecosystemQuality);
      } else if (currentScenario === "Increased system efficiency") {
        setCurrentTotal(
          totalData.increasedEfficiency.ecosystemQuality
        );
      } else if (currentScenario === "Attrition Rate (sorbent)") {
        setCurrentTotal(totalData.sorbentAttrition.ecosystemQuality);
      } else if (currentScenario === "Attrition Rate (catalyst)") {
        setCurrentTotal(totalData.catalystAttrition.ecosystemQuality);
      } else if (
        currentScenario ===
        "Increased production efficiency (sorbent)"
      ) {
        setCurrentTotal(totalData.efficiencySorbent.ecosystemQuality);
      } else if (
        currentScenario ===
        "Increased production efficiency (catalyst)"
      ) {
        setCurrentTotal(
          totalData.efficiencyCatalyst.ecosystemQuality
        );
      } else if (currentScenario === "Increased membrane lifetime") {
        setCurrentTotal(totalData.membrane.ecosystemQuality);
      }
    } else if (currentBarChart === "resources_depletion") {
      setReferenceLegendData({
        feedstock: resourcesDepletionReferenceData[0].data,
        electricity: resourcesDepletionReferenceData[1].data,
        directEmissions: resourcesDepletionReferenceData[2].data,
        catalyst: resourcesDepletionReferenceData[3].data,
        other: resourcesDepletionReferenceData[4].data,
      });
      setCurrentUnits(dataUnits.resourcesDepletion);
      setReferenceTotal(totalData.reference.resourcesDepletion);
      if (currentScenario === "Base scenario") {
        setCurrentTotal(totalData.base.resourcesDepletion);
      } else if (currentScenario === "Increased system efficiency") {
        setCurrentTotal(
          totalData.increasedEfficiency.resourcesDepletion
        );
      } else if (currentScenario === "Attrition Rate (sorbent)") {
        setCurrentTotal(
          totalData.sorbentAttrition.resourcesDepletion
        );
      } else if (currentScenario === "Attrition Rate (catalyst)") {
        setCurrentTotal(
          totalData.catalystAttrition.resourcesDepletion
        );
      } else if (
        currentScenario ===
        "Increased production efficiency (sorbent)"
      ) {
        setCurrentTotal(
          totalData.efficiencySorbent.resourcesDepletion
        );
      } else if (
        currentScenario ===
        "Increased production efficiency (catalyst)"
      ) {
        setCurrentTotal(
          totalData.efficiencyCatalyst.resourcesDepletion
        );
      } else if (currentScenario === "Increased membrane lifetime") {
        setCurrentTotal(totalData.membrane.resourcesDepletion);
      }
    } else if (currentBarChart === "water_consumption") {
      setReferenceLegendData({
        feedstock: waterConsumptionReferenceData[0].data,
        electricity: waterConsumptionReferenceData[1].data,
        directEmissions: waterConsumptionReferenceData[2].data,
        catalyst: waterConsumptionReferenceData[3].data,
        other: waterConsumptionReferenceData[4].data,
      });
      setCurrentUnits(dataUnits.waterConsumption);
      setReferenceTotal(totalData.reference.waterConsumption);
      if (currentScenario === "Base scenario") {
        setCurrentTotal(totalData.base.waterConsumption);
      } else if (currentScenario === "Increased system efficiency") {
        setCurrentTotal(
          totalData.increasedEfficiency.waterConsumption
        );
      } else if (currentScenario === "Attrition Rate (sorbent)") {
        setCurrentTotal(totalData.sorbentAttrition.waterConsumption);
      } else if (currentScenario === "Attrition Rate (catalyst)") {
        setCurrentTotal(totalData.catalystAttrition.waterConsumption);
      } else if (
        currentScenario ===
        "Increased production efficiency (sorbent)"
      ) {
        setCurrentTotal(totalData.efficiencySorbent.waterConsumption);
      } else if (
        currentScenario ===
        "Increased production efficiency (catalyst)"
      ) {
        setCurrentTotal(
          totalData.efficiencyCatalyst.waterConsumption
        );
      } else if (currentScenario === "Increased membrane lifetime") {
        setCurrentTotal(totalData.membrane.waterConsumption);
      }
    }
  }, [currentBarChart, currentScenario]);

  function precise(val) {
    if (val === 0) return "0";
    return Number.parseFloat(val).toExponential(2);
  }

  return (
    <div
      className={` w-full px-4 lg:w-[310px] bg-primary-10 rounded-md drop-shadow-4xl p-3 mr-4 mb-4 lg:mb-0 relative md:relative lg:absolute ${
        currentLevel === "systemLevel" ? "lg:top-[-30px]" : "lg:top-4"
      } lg:right-0`}
    >
      <p className="font-bold text-md">Legend </p>
      <div>
        <p className="font-bold text-md">System C</p>

        {currentLevel === "systemLevel" && (
          <ul className="w-full pr-1">
            <li className="flex flex-row justify-start items-center text-xs mb-1 flex-wrap">
              <div className="w-3 h-3 bg-[#FFC136] mr-3"></div>
              Feedstock production:
              <strong className="px-1">
                {precise(feedstockNum)}
              </strong>
              {currentUnits}
            </li>
            <li className="flex flex-row justify-start items-center text-xs mb-1 flex-wrap">
              <div className="w-3 h-3 bg-[#06BDF8] mr-3"></div>
              Electricity:
              <strong className="px-1">
                {precise(electricityNum)}
              </strong>
              {currentUnits}
            </li>
            <li className="flex flex-row justify-start items-center text-xs mb-1 flex-wrap">
              <div className="w-3 h-3 bg-[#7045EC] mr-3"></div>
              Direct emissions:
              <strong className="px-1">
                {precise(directemissionsNum)}
              </strong>
              {currentUnits}
            </li>
            <li className="flex flex-row justify-start items-center text-xs mb-1 flex-wrap">
              <div className="w-3 h-3 bg-[#3CC1A1] mr-3"></div>
              Catalyst:
              <strong className="px-1">{precise(catalystNum)}</strong>
              {currentUnits}
            </li>
            <li className="flex flex-row justify-start items-center text-xs mb-1 flex-wrap">
              <div className="w-3 h-3 bg-[#FE63AA] mr-3"></div>
              Pd Membrane:
              <strong className="px-1">
                {precise(pdmembraneNum)}
              </strong>
              {currentUnits}
            </li>
            <li className="flex flex-row justify-start items-center text-xs mb-1 flex-wrap">
              <div className="w-3 h-3 bg-[#FD86BD] mr-3"></div>
              Sorbent:
              <strong className="px-1">{precise(sorbentNum)}</strong>
              {currentUnits}
            </li>
            <li className="flex flex-row justify-start items-center text-xs mb-1 flex-wrap">
              <div className="w-3 h-3 bg-[#FCAAD0] mr-3"></div>
              Other:
              <strong className="px-1">{precise(otherNum)}</strong>
              {currentUnits}
            </li>
          </ul>
        )}

        {currentLevel === "materialLevel" && (
          <ul className="w-full pr-1">
            <li className="flex flex-row justify-start items-center text-xs mb-1 flex-wrap">
              <div className="w-3 h-3 bg-[#3CC1A1] mr-3"></div>
              Catalyst:
              <strong className="px-1">
                {precise(catalystMaterialNum)}
              </strong>
              {currentUnits}
            </li>
            <li className="flex flex-row justify-start items-center text-xs mb-1 flex-wrap">
              <div className="w-3 h-3 bg-[#FE63AA] mr-3"></div>
              Pd Membrane:
              <strong className="px-1">
                {precise(pdmembraneMaterialNum)}
              </strong>
              {currentUnits}
            </li>
            <li className="flex flex-row justify-start items-center text-xs mb-1 flex-wrap">
              <div className="w-3 h-3 bg-[#FD86BD] mr-3"></div>
              Sorbent:
              <strong className="px-1">
                {precise(sorbentMaterialNum)}
              </strong>
              {currentUnits}
            </li>
          </ul>
        )}

        <div className="flex flex-row justify-start items-center  my-2 flex-wrap">
          <p className="text-secondary-10 font-bold text-md">
            Total:
          </p>{" "}
          <strong className="text-md px-1">
            {precise(currentTotal)}
          </strong>{" "}
          {currentUnits}
        </div>
      </div>
      {reference && (
        <div>
          <p className="font-bold text-md">Reference</p>
          <ul className="w-1full ">
            <li className="flex flex-row justify-start items-center text-xs mb-1 flex-wrap">
              <div className="w-3 h-3 bg-[#FFC136] mr-3"></div>
              Feedstock production:
              <strong className="pr-1">
                {precise(referenceLegendData.feedstock)}
              </strong>
              {currentUnits}
            </li>
            <li className="flex flex-row justify-start items-center text-xs mb-1 flex-wrap">
              <div className="w-3 h-3 bg-[#06BDF8] mr-3"></div>
              Electricity:
              <strong className="pr-1">
                {precise(referenceLegendData.electricity)}
              </strong>
              {currentUnits}
            </li>
            <li className="flex flex-row justify-start items-center text-xs mb-1 flex-wrap">
              <div className="w-3 h-3 bg-[#7045EC] mr-3"></div>
              Direct Emissions:
              <strong className="pr-1">
                {precise(referenceLegendData.directEmissions)}
              </strong>
              {currentUnits}
            </li>
            <li className="flex flex-row justify-start items-center text-xs mb-1 flex-wrap">
              <div className="w-3 h-3 bg-[#3CC1A1] mr-3"></div>
              Catalyst:
              <strong className="pr-1">
                {precise(referenceLegendData.catalyst)}
              </strong>
              {currentUnits}
            </li>
            <li className="flex flex-row justify-start items-center text-xs mb-1 flex-wrap">
              <div className="w-3 h-3 bg-[#FCAAD0] mr-3"></div>
              Other:
              <strong className="pr-1">
                {precise(referenceLegendData.other)}
              </strong>
              {currentUnits}
            </li>
          </ul>
          <div className="flex flex-row justify-start items-center  my-2 flex-wrap">
            <p className="text-secondary-10 font-bold text-md">
              Total:
            </p>{" "}
            <strong className="text-md px-1">
              {precise(referenceTotal)}
            </strong>{" "}
            {currentUnits}
          </div>
        </div>
      )}
    </div>
  );
}

export default LegendC;
