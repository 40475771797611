import { useAtom } from "jotai";
import { systemCConclusionAtom } from "../components/SystemC";

function ConclusionC() {
  const [systemCConclusion, setSystemCConclusion] = useAtom(
    systemCConclusionAtom
  );
  return (
    <div
      className="conclusion-section h-[200px] w-full md:w-[50%] my-4 p-3 flex flex-col justify-between rounded-lg drop-shadow-4xl"
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.9)",
      }}
    >
      <div>
        <p className="text-base font-bold">CONCLUSION</p>
        <p className="h-[115px] overflow-hidden">
          The main contributors to <strong>climate change</strong>{" "}
          impacts for system C are the{" "}
          <strong>feedstock production</strong> of natural gas and
          associated leakage as well as electricity use respectively
          emissions from electricity generation. The{" "}
          <strong>MEMBER</strong> materials -{" "}
          <strong>Pd-based membrane</strong> ,{" "}
          <strong>catalyst</strong> , and <strong>sorbent</strong> -
          have a visible contribution, though not significant.
        </p>
      </div>
      <p
        className="text-right text-base font-bold underline cursor-pointer my-1.5"
        onClick={() => {
          setSystemCConclusion(true);
        }}
      >
        READ MORE
      </p>
    </div>
  );
}

export default ConclusionC;
