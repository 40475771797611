import memberIcon from "../assets/memberIcon.png";
import topArrow from "../assets/topArrow.png";

//flex flex-row justify-between

function Footer() {
  return (
    <footer
      className="relative h-fit md:h-fit lg:h-96 bg-primary-20 px-4 lg:px-0 py-6 flex flex-col md:flex-row lg:flex-row justify-between items-start overflow-hidden mx-auto"
      style={{ maxWidth: "1024px" }}
    >
      <div
        className="h-full flex flex-col sm:w-full sm:mb-16"
        style={{ minWidth: "300px", maxWidth: "338px" }}
      >
        <div>
          <img src={memberIcon} alt="" />
          <p className="text-base font-bold relative bottom-10">
            MEMBER
          </p>
        </div>
        <h2 className=" text-base font-semibold">
          © 2022 TECNALIA. All rights reserved
        </h2>
        <p>
          Parque Científico y Tecnológico de Bizkaia - C/ Geldo.
          Edificio 700. E-48160 Derio (Bizkaia). Tel.: 902.760.000 -
          International calls: (+34) 946.430.850
        </p>
      </div>
      <div className=" w-full flex flex-row justify-between md:justify-between lg:justify-between items-start gap-6 ml-0 md:ml-14 mt-8 md:mt-20">
        <div className="w-1/2 h-full flex flex-col mr-11 ">
          <div className="mb-8">
            <h2 className=" text-base font-semibold mr-8 mb-2">
              WEB SITE
            </h2>
            <a
              href="https://member-co2.com/"
              className="hover:underline"
            >
              member-co2.com
            </a>
          </div>
          <div>
            <h2 className=" text-base font-semibold mb-2">Contact</h2>
            <a
              className=" hover:underline"
              href="mailto: hello@quantis-intl.com"
            >
              hello@quantis-intl.com
            </a>
          </div>
        </div>
        <div
          className="w-1/2 h-full flex flex-col right-0"
          style={{ minWidth: "300px", maxWidth: "338px" }}
        >
          <h2 className=" text-base font-semibold mb-2">POLICIES</h2>
          <div className=" flex flex-col justify-start">
            <a
              href="https://www.tecnalia.com/en/legal-notice"
              target="_blank"
              className="hover:underline"
            >
              Legal Information{" "}
            </a>
            <a
              href="https://www.tecnalia.com/en/privacy-policy"
              target="_blank"
              className="my-1 hover:underline"
            >
              Policy
            </a>
            <a
              href="https://member-co2.com/content/cookie-preferences"
              target="_blank"
              className="hover:underline"
            >
              Cookie
            </a>
          </div>
        </div>
      </div>
      <a
        className="cursor-pointer drop-shadow-4xl absolute bottom-0 right-0 m-6 flex flex-row items-center gap-4 "
        onClick={() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }}
      >
        {" "}
        Back to top
        <img className="w-[25px] h-3" src={topArrow} alt="" />
      </a>
    </footer>
  );
}

export default Footer;
