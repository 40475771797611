import React from "react";
import { useAtom } from "jotai";
import { systemBConclusionAtom } from "./SystemB";

function ConclusionModalB(props) {
  const [systemBConclusion, setSystemBConclusion] = useAtom(
    systemBConclusionAtom
  );

  return (
    <div className="absolute left-0 top-2 z-40 bg-primary-10 w-[100%] md:w-1/2 h-fit p-11 rounded-md drop-shadow-4xl">
      <p className="font-bold">Conclusion</p>
      <p className="my-4">
        The impact categories <strong>climate change</strong> ,{" "}
        <strong>ecosystem quality</strong> and{" "}
        <strong>resources</strong> are also dominated by the coal used
        in the power plant from which the additional energy is taken.
        The impacts for <strong>climate change</strong> are related to
        energy intensive upstream processes which occur during mining
        and processing activities. The{" "}
        <strong>ecosystem quality</strong> is influenced by emissions
        to air and soil in the mining processes. The resource
        indicator reflects the depletion of mineral and fossil
        resources, which is why coal is the main contributor.{" "}
        <strong>Human Health</strong> impacts are closely related to
        other emissions than CO₂ in the coal combustion process as
        well as residual coal ash disposal. Water consumption is
        related to cooling processes and thus also directly linked to
        the additional power demand for the capture system.
      </p>
      <p
        className=" cursor-pointer text-right text-base font-bold underline "
        onClick={() => {
          setSystemBConclusion(false);
        }}
      >
        READ LESS{" "}
      </p>
    </div>
  );
}

export default ConclusionModalB;
