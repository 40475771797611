import { useAtom } from "jotai";
import React from "react";
import elipse from "../assets/Ellipse 45.png";
import { currentBarChartAtom } from "./SystemA";

function BarChartNavigationSystemA(props) {
  const [currentBarChart, setCurrentBarChart] = useAtom(
    currentBarChartAtom
  );
  const handleBarChart = props.handleBarChart;

  const menuItemStyles =
    "h-[25px] flex flex-col justify-between items-center";

  return (
    <div className="w-full mx-auto mb-4 md:mb-20 sticky top-0 z-50 bg-primary-20">
      <nav
        className=" text-dark-blue px-6 py-8 flex flex-row items-center justify-between sm:overflow-x-auto sm:whitespace-nowrap lg:flex-wrap overflow-hidden mx-auto"
        style={{
          overflow: "auto",
          whiteSpace: "nowrap",
          maxWidth: "1024px",
        }}
      >
        <li className={menuItemStyles}>
          <button
            onClick={() => {
              handleBarChart("climate");
            }}
            className="text-xs text-dark-blue font-bold px-6 whitespace-nowrap"
            role="menuitem"
            id="user-menu-item-0"
            style={{
              opacity: `${
                currentBarChart === "climate" ? "1" : "0.4"
              }`,
            }}
          >
            CLIMATE CHANGE
          </button>
          <img
            src={elipse}
            alt=""
            style={{
              display: `${
                currentBarChart === "climate" ? "inline" : "none"
              }`,
            }}
          />
        </li>
        <li className={menuItemStyles}>
          <button
            onClick={() => {
              handleBarChart("humanhealth");
            }}
            className="text-xs text-dark-blue font-bold px-6 whitespace-nowrap"
            role="menuitem"
            id="user-menu-item-1"
            style={{
              opacity: `${
                currentBarChart === "humanhealth" ? "1" : "0.4"
              }`,
            }}
          >
            HUMAN HEALTH
          </button>
          <img
            src={elipse}
            alt=""
            style={{
              display: `${
                currentBarChart === "humanhealth" ? "inline" : "none"
              }`,
            }}
          />
        </li>
        <li className={menuItemStyles}>
          <button
            onClick={() => {
              handleBarChart("ecosystem_quality");
            }}
            className="text-xs text-dark-blue font-bold px-6 whitespace-nowrap"
            role="menuitem"
            id="user-menu-item-2"
            style={{
              opacity: `${
                currentBarChart === "ecosystem_quality" ? "1" : "0.4"
              }`,
            }}
          >
            ECOSYSTEM QUALITY
          </button>
          <img
            src={elipse}
            alt=""
            style={{
              display: `${
                currentBarChart === "ecosystem_quality"
                  ? "inline"
                  : "none"
              }`,
            }}
          />
        </li>
        <li className={menuItemStyles}>
          <button
            onClick={() => {
              handleBarChart("resources_depletion");
            }}
            className="text-xs text-dark-blue font-bold px-6 whitespace-nowrap"
            role="menuitem"
            id="user-menu-item-2"
            style={{
              opacity: `${
                currentBarChart === "resources_depletion"
                  ? "1"
                  : "0.4"
              }`,
            }}
          >
            RESOURCES DEPLETION
          </button>
          <img
            src={elipse}
            alt=""
            style={{
              display: `${
                currentBarChart === "resources_depletion"
                  ? "inline"
                  : "none"
              }`,
            }}
          />
        </li>
        <li className={menuItemStyles}>
          <button
            onClick={() => {
              handleBarChart("water_consumption");
            }}
            className="text-xs text-dark-blue font-bold px-6 whitespace-nowrap"
            role="menuitem"
            id="user-menu-item-2"
            style={{
              opacity: `${
                currentBarChart === "water_consumption" ? "1" : "0.4"
              }`,
            }}
          >
            WATER CONSUMPTION
          </button>
          <img
            src={elipse}
            alt=""
            style={{
              display: `${
                currentBarChart === "water_consumption"
                  ? "inline"
                  : "none"
              }`,
            }}
          />
        </li>
      </nav>
    </div>
  );
}

export default BarChartNavigationSystemA;
