import { useAtom } from "jotai";
import { systemAConclusionAtom } from "../components/SystemA";

function Conclusion() {
  const [systemAConclusion, setSystemAConclusion] = useAtom(
    systemAConclusionAtom
  );
  return (
    <div
      className="conclusion-section h-[200px] w-full md:w-[50%] my-4 p-3 flex flex-col justify-between rounded-xl drop-shadow-4xl"
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.9)",
      }}
    >
      <div>
        <p className="text-base font-bold">CONCLUSION</p>
        <p className="h-[115px] overflow-hidden">
          The impact categories <strong>climate change</strong> ,{" "}
          <strong>ecosystem quality</strong> and{" "}
          <strong>resources</strong> are dominated by the feedstock
          (coal) of the system which reflects the additional energy
          needed for pre-combustion CO
          <sub>2</sub> capture with the MEMBER membrane (PBI).
        </p>
      </div>
      <p
        className="text-right text-base font-bold underline cursor-pointer my-1.5"
        onClick={() => {
          setSystemAConclusion(true);
        }}
      >
        READ MORE
      </p>
    </div>
  );
}

export default Conclusion;
