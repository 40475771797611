import { useAtom } from "jotai";
import { systemBConclusionAtom } from "../components/SystemB";

function ConclusionB() {
  const [systemBConclusion, setSystemBConclusion] = useAtom(
    systemBConclusionAtom
  );
  return (
    <div
      className="conclusion-section h-[200px] w-full md:w-[50%] my-4 p-3 flex flex-col justify-between rounded-lg drop-shadow-4xl"
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.9)",
      }}
    >
      <div>
        <p className="text-base font-bold">CONCLUSION</p>
        <p className="h-[115px] overflow-hidden">
          The impact categories <strong>climate change</strong> ,{" "}
          <strong>ecosystem quality</strong> and{" "}
          <strong>resources</strong> are also dominated by the coal
          used in the power plant from which the additional energy is
          taken. The impacts for <strong>climate change</strong> are
          related to energy intensive upstream processes...
        </p>
      </div>
      <p
        className="text-right text-base font-bold underline cursor-pointer my-1.5"
        onClick={() => {
          setSystemBConclusion(true);
        }}
      >
        READ MORE
      </p>
    </div>
  );
}

export default ConclusionB;
