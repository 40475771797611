import React from "react";
import { useAtom } from "jotai";
import { currentCaseAtom } from "./SystemA";
import { systemBcurrentCaseAtom } from "./SystemB";
import { systemBCaseParameters } from "../data/systemB";
import { systemACaseParameters } from "../data/systemA";

function Parameters(props) {
  const [currentCase] = useAtom(currentCaseAtom);
  const [systemBcurrentCase, setSystemBcurrentCase] = useAtom(
    systemBcurrentCaseAtom
  );
  const [currentCaseDetails, setCurrentCaseDetails] = React.useState(
    systemBCaseParameters.case1
  );
  const system = props.system;

  React.useEffect(() => {
    if (system === "systemA") {
      if (currentCase === 1) {
        setCurrentCaseDetails(systemACaseParameters.case1);
      } else if (currentCase === 2) {
        setCurrentCaseDetails(systemACaseParameters.case2);
      } else if (currentCase === 3) {
        setCurrentCaseDetails(systemACaseParameters.case3);
      }
    } else if (system === "systemB") {
      if (systemBcurrentCase === 1) {
        setCurrentCaseDetails(systemBCaseParameters.case1);
      } else if (systemBcurrentCase === 2) {
        setCurrentCaseDetails(systemBCaseParameters.case2);
      } else if (systemBcurrentCase === 3) {
        setCurrentCaseDetails(systemBCaseParameters.case3);
      } else if (systemBcurrentCase === 4) {
        setCurrentCaseDetails(systemBCaseParameters.case4);
      }
    }
  }, [systemBcurrentCase, currentCase]);

  return (
    <ul className="list-disc ml-5 py-2.5">
      <li>
        Total energy for CO<sub>2</sub> capture [MW]:{" "}
        <strong>{currentCaseDetails.totalEnergy}</strong>
      </li>
      <li>
        CO<sub>2</sub> recovery rate:{" "}
        <strong>{currentCaseDetails.recoveryRate}</strong>
      </li>
      <li>
        Total {system === "systemA" ? "PBI" : "PEBAX"} membrane area
        [km²]: <strong>{currentCaseDetails.memberaneArea}</strong>
      </li>
    </ul>
  );
}

export default Parameters;
