import React from "react";
import { communicateInformation } from "../common/shared";

function Communicate() {
  return (
    <div>
      <div
        className="mx-auto flex flex-row px-4 lg:px-6 space-x-9 py-16 sm:h-1/4 md:h-1/4 lg:h-fit sm:overflow-x-auto overflow-hidden "
        style={{
          maxWidth: "1024px",
          overflow: "auto",
          whiteSpace: "nowrap",
        }}
      >
        {communicateInformation.map((item) => {
          return (
            <div
              className="w-2/5 z-50 drop-shadow-4xl bg-white p-6 basis-1/3 rounded-md"
              style={{ minWidth: "300px", whiteSpace: "normal" }}
            >
              <h2 className="text-dark-blue text-base font-semibold mb-5">
                {item.title}
              </h2>
              <p className="pr-2 text-dark-blue text-base font-semibold mr-3">
                {item.text}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Communicate;
