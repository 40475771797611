import React from "react";
import Navigation from "./Navigation";
import introHero from "../assets/introhero.png";

function Introduction() {
  return (
    <div
      className="relative bg-cover"
      style={{
        height: "85vh",
        backgroundImage: `url(${introHero})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        borderRadius: "0 0 30px 30px",
      }}
    >
      <Navigation />

      <div className="absolute bottom-0 text-dark-blue w-full ">
        <div
          style={{
            backgroundImage:
              "linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1))",
          }}
        >
          <div style={{ maxWidth: "1024px" }} className="mx-auto">
            <h1 className="text-dark-blue px-4 lg:px-0 py-2 text-md md:text-base font-bold">
              INTRODUCTION
            </h1>
            <h2 className="w-full px-4 lg:px-0 pr-8  text-xl md:text-3xl font-bold leading-11 flex-wrap">
              "Advanced MEMBranes and membrane assisted procEsses for
              pre- and post- combustion CO₂ captuRe"
            </h2>
          </div>
        </div>
        <div className="w-full bg-white pb-5 rounded-b-2xl">
          <div>
            <p
              className="px-4 lg:px-0 py-8 pr-8  font-semibold bg-white text-sm md:text-lg leading-6 md:leading-9 mx-auto"
              style={{ maxWidth: "1024px" }}
            >
              The MEMBER project's objective is the scale-up of
              advanced materials in membrane based technologies for
              pre- and post-combustion CO₂ capture in power plants as
              well as H₂ generation with integrated CO₂ capture.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full h-[210px] absolute top-[44rem] border-b-4 border-x-4 rounded-b-3xl border-white z-10 bg-transparent">
        {" "}
      </div>
    </div>
  );
}

export default Introduction;
