//common
export const systemACaseParameters = {
  case1: {
    totalEnergy: 66.7,
    recoveryRate: "90%",
    memberaneArea: 0.37,
  },
  case2: {
    totalEnergy: 48.7,
    recoveryRate: "90%",
    memberaneArea: 0.145,
  },
  case3: {
    totalEnergy: 50,
    recoveryRate: "90%",
    memberaneArea: 0.08,
  },
};

//DATA

const labels = ["", ""];

export const climateReferenceData = [
  {
    label: "Material and Waste",
    data: [0.0003836933926],
    backgroundColor: "#FF489C",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Feedstock Production",
    data: [0.057236078],
    backgroundColor: "#FFC136",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Direct Emissions",
    data: [0.037342495],
    backgroundColor: "#7045EC",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
];

export const humanHealthReferenceData = [
  {
    label: "Material and Waste",
    data: [0.00000002885291471],
    backgroundColor: "#FF489C",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Feedstock Production",
    data: [0.000000014152382],
    backgroundColor: "#FFC136",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Direct Emissions",
    data: [0.000000044744052],
    backgroundColor: "#7045EC",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
];

export const ecosystemQualityReferenceData = [
  {
    label: "Material and Waste",
    data: [0.000978753032],
    backgroundColor: "#FF489C",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Feedstock Production",
    data: [0.0088268785],
    backgroundColor: "#FFC136",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Direct Emissions",
    data: [0.0036862665],
    backgroundColor: "#7045EC",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
];

export const resourcesDepletionReferenceData = [
  {
    label: "Material and Waste",
    data: [0.008635619085],
    backgroundColor: "#FF489C",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Feedstock Production",
    data: [3.6413349],
    backgroundColor: "#FFC136",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Direct Emissions",
    data: [0.024297386],
    backgroundColor: "#7045EC",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
];

export const waterConsumptionReferenceData = [
  {
    label: "Material and Waste",
    data: [0.000004145767368],
    backgroundColor: "#FF489C",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Feedstock Production",
    data: [0.000066172164],
    backgroundColor: "#FFC136",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
  {
    label: "Direct Emissions",
    data: [0.000216408846],
    backgroundColor: "#7045EC",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 1",
  },
];

export const placeholderData = [
  {
    label: "blank",
    data: [0.031054671],
    backgroundColor: "transparent",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 2",
  },
  {
    label: "blank",
    data: [0.057236078],
    backgroundColor: "transparent ",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 2",
  },
  {
    label: "blank",
    data: [0.006287824],
    backgroundColor: "transparent ",
    categoryPercentage: 1.0,
    barPercentage: 0.75,
    stack: "Stack 2",
  },
];

export const totalData = {
  case1: {
    climate: 0.040064136,
    humanHealth: 0.000000037204016,
    ecosystemQuality: 0.0066082932,
    resourcesDepletion: 1.8059066,
    waterConsumption: 0.00014492739,
  },
  case2: {
    climate: 0.027840647,
    humanHealth: 0.000000025997268,
    ecosystemQuality: 0.0045736539,
    resourcesDepletion: 1.2635613,
    waterConsumption: 0.000099649017,
  },
  case3: {
    climate: 0.028463333,
    humanHealth: 0.00000002666631,
    ecosystemQuality: 0.0046647687,
    resourcesDepletion: 1.2970924,
    waterConsumption: 0.0001012283,
  },
  reference: {
    climate: 0.094962266,
    humanHealth: 0.000000087749349,
    ecosystemQuality: 0.013491898,
    resourcesDepletion: 3.6742679,
    waterConsumption: 0.00028672678,
  },
};
//----CASE 1

export const climateDataCase1 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.000028457665],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.000069101599],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PBI membrane",
      data: [0.0010292393],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal",
      data: [0.027851802],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.011085535],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const humanHealthDataCase1 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.00000000000882],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.00000000802],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PBI membrane",
      data: [0.000000000511],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [0.00000000689],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.000000021773023],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const ecosystemQualityDataCase1 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.0000040105428],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.00028561827],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PBI membrane",
      data: [0.00022960924],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [0.0042952711],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.0017937841],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const resourcesDepletionDataCase1 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.00014737801],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.0022803041],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PBI membrane",
      data: [0.019735743],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [1.7719198],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.011823417],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const waterConsumptionDataCase1 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.00000009879174],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.0000001714067],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PBI membrane",
      data: [0.0000071478556],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [0.000032200215],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.00010530911],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const climateDataWithReferenceCase1 = {
  labels: [""],
  datasets: [
    ...climateDataCase1.datasets,
    ...climateReferenceData,
    ...placeholderData,
  ],
};

export const humanHealthDataWithReferenceCase1 = {
  labels: [""],
  datasets: [
    ...humanHealthDataCase1.datasets,
    ...humanHealthReferenceData,
    ...placeholderData,
  ],
};

export const ecosystemQualityDataWithReferenceCase1 = {
  labels: [""],
  datasets: [
    ...ecosystemQualityDataCase1.datasets,
    ...ecosystemQualityReferenceData,
    ...placeholderData,
  ],
};

export const resourcesDepletionDataWithReferenceCase1 = {
  labels: [""],
  datasets: [
    ...resourcesDepletionDataCase1.datasets,
    ...resourcesDepletionReferenceData,
    ...placeholderData,
  ],
};

export const waterConsumptionDataWithReferenceCase1 = {
  labels: [""],
  datasets: [
    ...waterConsumptionDataCase1.datasets,
    ...waterConsumptionReferenceData,
    ...placeholderData,
  ],
};

//----CASE 2

export const climateDataCase2 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.00001115138],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.000048589769],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PBI membrane",
      data: [0.00040331623],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal",
      data: [0.019582862],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.0077947282],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const humanHealthDataCase2 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.0000000000034562291],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.0000000056427993],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PBI membrane",
      data: [0.00000000020007359],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [0.0000000048421233],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.000000015308816],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const ecosystemQualityDataCase2 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.0000015715655],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.00020083654],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PBI membrane",
      data: [0.000089974346],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [0.0030200452],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.0012612263],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const resourcesDepletionDataCase2 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.000057751332],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.0016034282],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PBI membrane",
      data: [0.0077336198],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [1.2458533],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.0083131545],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const waterConsumptionDataCase2 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.000000038712388],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.00000012052705],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PBI membrane",
      data: [0.0000028009484],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [0.000022640272],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.00007404855],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const climateDataWithReferenceCase2 = {
  labels: [""],
  datasets: [
    ...climateDataCase2.datasets,
    ...climateReferenceData,
    ...placeholderData,
  ],
};

export const humanHealthDataWithReferenceCase2 = {
  labels: [""],
  datasets: [
    ...humanHealthDataCase2.datasets,
    ...humanHealthReferenceData,
    ...placeholderData,
  ],
};

export const ecosystemQualityDataWithReferenceCase2 = {
  labels: [""],
  datasets: [
    ...ecosystemQualityDataCase2.datasets,
    ...ecosystemQualityReferenceData,
    ...placeholderData,
  ],
};

export const resourcesDepletionDataWithReferenceCase2 = {
  labels: [""],
  datasets: [
    ...resourcesDepletionDataCase2.datasets,
    ...resourcesDepletionReferenceData,
    ...placeholderData,
  ],
};

export const waterConsumptionDataWithReferenceCase2 = {
  labels: [""],
  datasets: [
    ...waterConsumptionDataCase2.datasets,
    ...waterConsumptionReferenceData,
    ...placeholderData,
  ],
};

//----CASE 3

export const climateDataCase3 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.0000061549062],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.000050020482],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PBI membrane",
      data: [0.00022260686],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal",
      data: [0.020160198],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.008024353],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const humanHealthDataCase3 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.0000000000019076353],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.00000000580895],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PBI membrane",
      data: [0.00000000011042887],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [0.0000000049848773],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.000000015760146],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const ecosystemQualityDataCase3 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.0000008674118],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.00020675012],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PBI membrane",
      data: [0.000049660552],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [0.0031090812],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.0012984094],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const resourcesDepletionDataCase3 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.000031875342],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.0016506407],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PBI membrane",
      data: [0.0042685037],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [1.2825832],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.0085582404],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const waterConsumptionDataCase3 = {
  labels,
  datasets: [
    {
      label: "Membrane EoL",
      data: [0.000000021366963],
      backgroundColor: "#FCAAD0",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard coal ash",
      data: [0.00000012407594],
      backgroundColor: "#FD86BD",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "PBI membrane",
      data: [0.0000015459589],
      backgroundColor: "#FE63AA",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Hard Coal",
      data: [0.000023307745],
      backgroundColor: "#FFC136",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
    {
      label: "Direct emissions",
      data: [0.00007622915],
      backgroundColor: "#7045EC",
      categoryPercentage: 1.0,
      barPercentage: 0.75,
      stack: "Stack 0",
    },
  ],
};

export const climateDataWithReferenceCase3 = {
  labels: [""],
  datasets: [
    ...climateDataCase3.datasets,
    ...climateReferenceData,
    ...placeholderData,
  ],
};

export const humanHealthDataWithReferenceCase3 = {
  labels: [""],
  datasets: [
    ...humanHealthDataCase3.datasets,
    ...humanHealthReferenceData,
    ...placeholderData,
  ],
};

export const ecosystemQualityDataWithReferenceCase3 = {
  labels: [""],
  datasets: [
    ...ecosystemQualityDataCase3.datasets,
    ...ecosystemQualityReferenceData,
    ...placeholderData,
  ],
};

export const resourcesDepletionDataWithReferenceCase3 = {
  labels: [""],
  datasets: [
    ...resourcesDepletionDataCase3.datasets,
    ...resourcesDepletionReferenceData,
    ...placeholderData,
  ],
};

export const waterConsumptionDataWithReferenceCase3 = {
  labels: [""],
  datasets: [
    ...waterConsumptionDataCase3.datasets,
    ...waterConsumptionReferenceData,
    ...placeholderData,
  ],
};

//REFERENCE DATA

export const dataCase1 = {
  climateData: climateDataCase1,
  humanHealthData: humanHealthDataCase1,
  ecosystemQualityData: ecosystemQualityDataCase1,
  resourcesDepletionData: resourcesDepletionDataCase1,
  waterConsumptionData: waterConsumptionDataCase1,
};

export const dataCase2 = {
  climateData: climateDataCase2,
  humanHealthData: humanHealthDataCase2,
  ecosystemQualityData: ecosystemQualityDataCase2,
  resourcesDepletionData: resourcesDepletionDataCase2,
  waterConsumptionData: waterConsumptionDataCase2,
};

export const dataCase3 = {
  climateData: climateDataCase3,
  humanHealthData: humanHealthDataCase3,
  ecosystemQualityData: ecosystemQualityDataCase3,
  resourcesDepletionData: resourcesDepletionDataCase3,
  waterConsumptionData: waterConsumptionDataCase3,
};

export const referenceDataCase1 = {
  climateData: climateDataWithReferenceCase1,
  humanHealthData: humanHealthDataWithReferenceCase1,
  ecosystemQualityData: ecosystemQualityDataWithReferenceCase1,
  resourcesDepletionData: resourcesDepletionDataWithReferenceCase1,
  waterConsumptionData: waterConsumptionDataWithReferenceCase1,
};

export const referenceDataCase2 = {
  climateData: climateDataWithReferenceCase2,
  humanHealthData: humanHealthDataWithReferenceCase2,
  ecosystemQualityData: ecosystemQualityDataWithReferenceCase2,
  resourcesDepletionData: resourcesDepletionDataWithReferenceCase2,
  waterConsumptionData: waterConsumptionDataWithReferenceCase2,
};

export const referenceDataCase3 = {
  climateData: climateDataWithReferenceCase3,
  humanHealthData: humanHealthDataWithReferenceCase3,
  ecosystemQualityData: ecosystemQualityDataWithReferenceCase3,
  resourcesDepletionData: resourcesDepletionDataWithReferenceCase3,
  waterConsumptionData: waterConsumptionDataWithReferenceCase3,
};
