import React from "react";
import { useAtom } from "jotai";
import { currentBarChartAtom } from "./SystemA";
import { chartInformation } from "../common/shared";

function ChartInformation() {
  const [currentBarChart] = useAtom(currentBarChartAtom);
  const [currentInformationDetails, setCurrentInformationDetails] =
    React.useState(chartInformation.climate);

  React.useEffect(() => {
    if (currentBarChart === "climate") {
      setCurrentInformationDetails(chartInformation.climate);
    } else if (currentBarChart === "humanhealth") {
      setCurrentInformationDetails(chartInformation.humanHealth);
    } else if (currentBarChart === "ecosystem_quality") {
      setCurrentInformationDetails(chartInformation.ecosystemQuality);
    } else if (currentBarChart === "resources_depletion") {
      setCurrentInformationDetails(
        chartInformation.resourcesDepletion
      );
    } else if (currentBarChart === "water_consumption") {
      setCurrentInformationDetails(chartInformation.waterConsumption);
    }
  }, [currentBarChart]);

  return (
    <div
      className="w-[95%] md:h-[370px] lg:h-[270px] flex flex-col md:flex-row lg:flex-row justify-start rounded-3xl drop-shadow-4xl mx-auto mt-4"
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.9)",
      }}
    >
      <img
        src={currentInformationDetails.image}
        alt=""
        className="w-80 h-80 drop-shadow-4xl relative bottom-0 right-0 md:bottom-12 md:right-12 self-center"
      />
      <div className="w-full flex flex-col justify-between mt-4 p-4">
        <div className="flex flex-col mb-6">
          <p className="text-2xl md:text-3xl font-bold mb-1.5">
            {currentInformationDetails.title}
          </p>
          <p className="w-full md:w-5/6 lg:w-5/6 font-semibold text-sm md:text-md pr-0 ">
            {currentInformationDetails.description}
          </p>
        </div>
        <div className="flex flex-col mb-4">
          <p className="font-semibold text-sm md:text-md">UNIT</p>
          <p className="font-semibold text-sm md:text-md">
            <strong>{currentInformationDetails.units}</strong>{" "}
            <p className="font-normal">
              {currentInformationDetails.unitDescription}
            </p>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ChartInformation;
