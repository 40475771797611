import React from "react";
import { referenceAtom, currentBarChartAtom } from "./SystemA";
import { systemBcurrentCaseAtom } from "./SystemB";
import {
  climateReferenceData,
  humanHealthReferenceData,
  ecosystemQualityReferenceData,
  resourcesDepletionReferenceData,
  waterConsumptionReferenceData,
  totalData,
} from "../data/systemB";
import { dataUnits } from "../common/shared";
import { atom, useAtom } from "jotai";

const currentUnitsAtom = atom("kg CO₂-eq");

function LegendB(props) {
  let memberaneNum = props.currentLegendData.memberane[0];
  let coalAshNum = props.currentLegendData.coalAsh[0];
  let pbiNum = props.currentLegendData.pbi[0];
  let hardCoalNum = props.currentLegendData.hardCoal[0];
  let directEmissionsNum = props.currentLegendData.directEmissions[0];

  const [reference] = useAtom(referenceAtom);
  const [currentCase] = useAtom(systemBcurrentCaseAtom);
  const [currentBarChart] = useAtom(currentBarChartAtom);
  const [referenceLegendData, setReferenceLegendData] =
    React.useState({
      material: climateReferenceData[0].data,
      feedstock: climateReferenceData[1].data,
      directEmissions: climateReferenceData[2].data,
    });
  const [currentUnits, setCurrentUnits] = useAtom(currentUnitsAtom);
  const [currentTotal, setCurrentTotal] = React.useState(
    totalData.case1.climate
  );
  const [referenceTotal, setReferenceTotal] = React.useState(
    totalData.reference.climate
  );

  React.useEffect(() => {
    if (currentBarChart === "climate") {
      setReferenceLegendData({
        material: climateReferenceData[0].data,
        feedstock: climateReferenceData[1].data,
        directEmissions: climateReferenceData[2].data,
      });
      setCurrentUnits(dataUnits.climate);
      setReferenceTotal(totalData.reference.climate);
      if (currentCase === 1) {
        setCurrentTotal(totalData.case1.climate);
      } else if (currentCase === 2) {
        setCurrentTotal(totalData.case2.climate);
      } else if (currentCase === 3) {
        setCurrentTotal(totalData.case3.climate);
      } else if (currentCase === 4) {
        setCurrentTotal(totalData.case4.climate);
      }
    } else if (currentBarChart === "humanhealth") {
      setReferenceLegendData({
        material: humanHealthReferenceData[0].data,
        feedstock: humanHealthReferenceData[1].data,
        directEmissions: humanHealthReferenceData[2].data,
      });
      setCurrentUnits(dataUnits.humanHealth);
      setReferenceTotal(totalData.reference.humanHealth);
      if (currentCase === 1) {
        setCurrentTotal(totalData.case1.humanHealth);
      } else if (currentCase === 2) {
        setCurrentTotal(totalData.case2.humanHealth);
      } else if (currentCase === 3) {
        setCurrentTotal(totalData.case3.humanHealth);
      } else if (currentCase === 4) {
        setCurrentTotal(totalData.case4.humanHealth);
      }
    } else if (currentBarChart === "ecosystem_quality") {
      setReferenceLegendData({
        material: ecosystemQualityReferenceData[0].data,
        feedstock: ecosystemQualityReferenceData[1].data,
        directEmissions: ecosystemQualityReferenceData[2].data,
      });
      setCurrentUnits(dataUnits.ecosystemQuality);
      setReferenceTotal(totalData.reference.ecosystemQuality);
      if (currentCase === 1) {
        setCurrentTotal(totalData.case1.ecosystemQuality);
      } else if (currentCase === 2) {
        setCurrentTotal(totalData.case2.ecosystemQuality);
      } else if (currentCase === 3) {
        setCurrentTotal(totalData.case3.ecosystemQuality);
      } else if (currentCase === 4) {
        setCurrentTotal(totalData.case4.ecosystemQuality);
      }
    } else if (currentBarChart === "resources_depletion") {
      setReferenceLegendData({
        material: resourcesDepletionReferenceData[0].data,
        feedstock: resourcesDepletionReferenceData[1].data,
        directEmissions: resourcesDepletionReferenceData[2].data,
      });
      setCurrentUnits(dataUnits.resourcesDepletion);
      setReferenceTotal(totalData.reference.resourcesDepletion);
      if (currentCase === 1) {
        setCurrentTotal(totalData.case1.resourcesDepletion);
      } else if (currentCase === 2) {
        setCurrentTotal(totalData.case2.resourcesDepletion);
      } else if (currentCase === 3) {
        setCurrentTotal(totalData.case3.resourcesDepletion);
      } else if (currentCase === 4) {
        setCurrentTotal(totalData.case4.resourcesDepletion);
      }
    } else if (currentBarChart === "water_consumption") {
      setReferenceLegendData({
        material: waterConsumptionReferenceData[0].data,
        feedstock: waterConsumptionReferenceData[1].data,
        directEmissions: waterConsumptionReferenceData[2].data,
      });
      setCurrentUnits(dataUnits.waterConsumption);
      setReferenceTotal(totalData.reference.waterConsumption);
      if (currentCase === 1) {
        setCurrentTotal(totalData.case1.waterConsumption);
      } else if (currentCase === 2) {
        setCurrentTotal(totalData.case2.waterConsumption);
      } else if (currentCase === 3) {
        setCurrentTotal(totalData.case3.waterConsumption);
      } else if (currentCase === 4) {
        setCurrentTotal(totalData.case4.waterConsumption);
      }
    }
  }, [currentBarChart, currentCase]);

  function precise(val) {
    if (val === 0) return "0";
    return Number.parseFloat(val).toExponential(2);
  }

  return (
    <div className=" w-full px-4 lg:w-[310px] bg-primary-10 rounded-md drop-shadow-4xl p-3 mr-4 mb-4 lg:mb-0 relative md:relative lg:absolute lg:top-4 lg:right-0">
      <p className="font-bold text-md">Legend </p>
      <div>
        <p className="font-bold text-md">System B</p>
        <ul className="w-full pr-1">
          <li className="flex flex-row justify-start items-center text-xs mb-1 flex-wrap">
            <div className="w-3 h-3 bg-legend-a-10 mr-3"></div>
            Membrane EoL:
            <strong className="px-1">{precise(memberaneNum)}</strong>
            {currentUnits}
          </li>
          <li className="flex flex-row justify-start items-center text-xs mb-1 flex-wrap">
            <div className="w-3 h-3 bg-legend-a-20 mr-3"></div>
            Hard coal ash:
            <strong className="px-1">{precise(coalAshNum)}</strong>
            {currentUnits}
          </li>
          <li className="flex flex-row justify-start items-center text-xs mb-1 flex-wrap">
            <div className="w-3 h-3 bg-legend-a-30 mr-3"></div>
            PEBAX memberane:
            <strong className="px-1">{precise(pbiNum)}</strong>
            {currentUnits}
          </li>
          <li className="flex flex-row justify-start items-center text-xs mb-1 flex-wrap">
            <div className="w-3 h-3 bg-legend-a-40 mr-3"></div>
            Hard coal:
            <strong className="px-1">{precise(hardCoalNum)}</strong>
            {currentUnits}
          </li>
          <li className="flex flex-row justify-start items-center text-xs mb-1 flex-wrap">
            <div className="w-3 h-3 bg-legend-a-50 mr-3"></div>
            Direct emissions:
            <strong className="px-1">
              {precise(directEmissionsNum)}
            </strong>
            {currentUnits}
          </li>
        </ul>
        <div className="flex flex-row justify-start items-center  my-2 flex-wrap">
          <p className="text-secondary-10 font-bold text-md">
            Total:
          </p>{" "}
          <strong className="text-md px-1">
            {precise(currentTotal)}
          </strong>{" "}
          {currentUnits}
        </div>
      </div>
      {reference && (
        <div>
          <p className="font-bold text-md">Reference</p>
          <ul className="w-1full ">
            <li className="flex flex-row justify-start items-center text-xs mb-1 flex-wrap">
              <div className="w-3 h-3 bg-legend-ref-10 mr-3"></div>
              Material and Waste:
              <strong className="pr-1">
                {precise(referenceLegendData.material)}
              </strong>
              {currentUnits}
            </li>
            <li className="flex flex-row justify-start items-center text-xs mb-1 flex-wrap">
              <div className="w-3 h-3 bg-legend-ref-20 mr-3"></div>
              Feedstock Production:
              <strong className="pr-1">
                {precise(referenceLegendData.feedstock)}
              </strong>
              {currentUnits}
            </li>
            <li className="flex flex-row justify-start items-center text-xs mb-1 flex-wrap">
              <div className="w-3 h-3 bg-legend-ref-30 mr-3"></div>
              Direct Emissions:
              <strong className="pr-1">
                {precise(referenceLegendData.directEmissions)}
              </strong>
              {currentUnits}
            </li>
          </ul>
          <div className="flex flex-row justify-start items-center  my-2 flex-wrap">
            <p className="text-secondary-10 font-bold text-md">
              Total:
            </p>{" "}
            <strong className="text-md px-1">
              {precise(referenceTotal)}
            </strong>{" "}
            {currentUnits}
          </div>
        </div>
      )}
    </div>
  );
}

export default LegendB;
